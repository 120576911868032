<template>
  <div>
    <v-data-table
      :headers="placeHeaders"
      :items="places"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="toolbar-left">
            <v-card-title>
              <v-toolbar-title class="panel-with">
                <h3 class="panel-title">Most checked in places</h3>
              </v-toolbar-title>
            </v-card-title>
          </div>
          <div class="toolbar-right">
            <div class="filter-select">
              <filter-by-date-dashboard
                :newFilterOption="newFilterOption"
                @changedFilterOption="getChangedFilterOption"
              ></filter-by-date-dashboard>
            </div>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.sn="{ index }">
        {{ index + 1 }}
      </template>
    </v-data-table>
    <!-- <pagination
      :totalItems="pagination.totalItems + ' Places'"
      :totalPages="pagination.totalPages"
      :perPage="pagination.perPage"
      :currentPage="pagination.currentPage"
      @pagechanged="getData"
    ></pagination> -->
  </div>
</template>

<script>
import pagination from "../subComponents/pagination.vue";
import FilterByDateDashboard from "./filterByDateDashboard.vue";
export default {
  name: "checkInPlacesComponent",
  components: { pagination, FilterByDateDashboard },
  data() {
    return {
      loading: false,
      newFilterOption: null,
      places: [],
      filterBy: null,
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      placeHeaders: [
        {
          text: "S.N.",
          align: "start",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "Place Name",
          value: "place_name",
          sortable: false,
          align: "start",
        },
        {
          text: "Checked in",
          value: "checkin",
          sortable: true,
          align: "center",
          width: "140px",
        },
        {
          text: "Active Offers",
          value: "offers",
          sortable: true,
          align: "center",
          width: "165px",
        },
      ],
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getData(this.pagination.currentPage);
  },
  methods: {
    getData(page) {
      this.loading = true;
      axios
        .get(`/admin/most-check-in?date=` + this.filterBy)
        .then(({ data }) => {
          //   console.log("checkin", data);
          this.places = data.data;
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    getChangedFilterOption(option) {
      this.filterBy = option;
      this.getData(this.pagination.currentPage);
    },
  },
};
</script>

<style></style>
