<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
  >
    <path
      d="M4.82139 9.64278C7.48417 9.64278 9.64278 7.48417 9.64278 4.82139C9.64278 2.15861 7.48417 0 4.82139 0C2.15861 0 0 2.15861 0 4.82139C0 7.48417 2.15861 9.64278 4.82139 9.64278Z"
      fill="white"
    />
    <path
      d="M20.8931 9.64296C23.5559 9.64296 25.7145 7.48435 25.7145 4.82157C25.7145 2.15879 23.5559 0.000183105 20.8931 0.000183105C18.2303 0.000183105 16.0717 2.15879 16.0717 4.82157C16.0717 7.48435 18.2303 9.64296 20.8931 9.64296Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.82139 15.0001C6.15278 15.0001 7.35813 15.5398 8.23063 16.4123C9.10313 17.2848 9.64278 18.4901 9.64278 19.8215V30H0V19.8215C0 18.4901 0.539652 17.2848 1.41215 16.4123C2.28465 15.5398 3.49 15.0001 4.82139 15.0001ZM20.8927 15.0001C22.2241 15.0001 23.4294 15.5398 24.3019 16.4123C25.1744 17.2848 25.7141 18.4901 25.7141 19.8215V30H16.0713V19.8215C16.0713 18.4901 16.611 17.2848 17.4835 16.4123C18.3559 15.5398 19.5613 15.0001 20.8927 15.0001Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIcon",
};
</script>
