<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-profile-user">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <h3 class="panel-title">Profile</h3>
              </div>
            </div>
          </div>
          <div class="panel-body py-4">
            <v-app>
              <template>
                <div class="form-wrap">
                  <div class="label-wrap">
                    <h5 class="label">Name:</h5>
                  </div>
                  <div class="label-wrap-content">
                    <p class="label-text">{{ admin.name }}</p>
                  </div>
                </div>
                <div class="form-wrap">
                  <div class="label-wrap">
                    <h5 class="label">Email:</h5>
                  </div>
                  <div class="label-wrap-content">
                    <p class="label-text">{{ admin.email }}</p>
                  </div>
                </div>
                <div class="form-wrap">
                  <div class="label-wrap">
                    <h5 class="label">Registration Date:</h5>
                  </div>
                  <div class="label-wrap-content">
                    <p class="label-text">
                      {{ admin.email_verified_at | moment(" MMMM-D-YYYY") }}
                    </p>
                  </div>
                </div>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="actions">
                      <a v-bind="attrs" v-on="on" class="btn-text">
                        Change Password
                      </a>
                    </div>
                  </template>
                  <form @submit.prevent="changePassword">
                    <v-card class="modalTagAdd">
                      <div class="modal-head">
                        <h3 class="modal-title">Change Password</h3>
                      </div>

                      <div class="modal-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-wrap">
                              <label for="old_password">Old Password</label>
                              <input
                                type="password"
                                v-model="form.old_password"
                                @input="errors.old_password = null"
                                @click="errors.old_password = null"
                              />
                              <small
                                class="text-danger"
                                v-if="errors.old_password"
                              >
                                {{ errors.old_password[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-wrap">
                              <label for="new_password">New Password</label>
                              <input
                                type="password"
                                v-model="form.new_password"
                                @input="errors.new_password = null"
                                @click="errors.new_password = null"
                              />
                              <small
                                class="text-danger"
                                v-if="errors.new_password"
                              >
                                {{ errors.new_password[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-wrap last">
                              <label for="confirm_password"
                                >Confirm Password</label
                              >
                              <input
                                type="password"
                                v-model="form.confirm_password"
                                @input="errors.confirm_password = null"
                                @click="errors.confirm_password = null"
                              />
                              <small
                                class="text-danger"
                                v-if="errors.confirm_password"
                              >
                                {{ errors.confirm_password[0] }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer justify-content-start">
                        <div class="actions">
                          <button class="btn btn-primary" text type="submit">
                            Update
                          </button>
                          <a
                            class="btn btn-secondary"
                            text
                            @click="closeDialog"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </v-card>
                  </form>
                </v-dialog>
              </template>
            </v-app>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>



<script>
import BackArrow from "../icons/BackArrow.vue";
// import ImageDragDrap from "../subComponents/imageDragDrop.vue";
export default {
  name: "Profile",
  components: {
    BackArrow,
    // ImageDragDrap,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      admin: {},
      form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      errors: {},
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getAdmin();
  },
  methods: {
    getAdmin() {
      axios
        .get(`/admin/admin`)
        .then(({ data }) => {
          this.admin = data;
        })
        .catch((error) => console.log(error));
    },
    changePassword() {
      this.loading = true;
      axios
        .post(`/admin/change-password`, this.form)
        .then(({ data }) => {
          this.loading = false;
          this.dialog = false;
          Toast.success();
          this.form = {
            old_password: "",
            new_password: "",
            confirm_password: "",
          };
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.errors= {};
      this.form = {
        old_password: "",
        new_password: "",
        confirm_password: "",
      };
    },
  },
};
</script>

<style>
</style>
