<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M22.5263 21.3158C27.4058 22.0308 29.6316 23.4664 29.6316 25.3664C29.6316 27.9255 23.446 30 15.8158 30C8.18554 30 2 27.9255 2 25.3664C2 23.4664 3.83105 22.0308 8.71053 21.3158"
      stroke="white"
      stroke-width="2.8125"
      stroke-linecap="round"
    />
    <path
      d="M7.13369 8.80686C7.03136 4.15197 10.63 0.375052 15.0814 0.0271781C19.618 -0.320696 23.5407 2.71078 24.3423 6.83557C24.7346 8.87312 24.3935 10.8278 23.336 12.6335C21.153 16.3607 18.9529 20.0714 16.7528 23.7986C16.2241 24.7097 15.4054 24.6931 14.8597 23.782C12.6766 20.0714 10.4765 16.3607 8.29344 12.65C7.54301 11.3745 7.11664 9.98301 7.13369 8.80686ZM15.8147 5.36125C13.6658 5.36125 11.8921 7.06749 11.9091 9.15473C11.9262 11.2088 13.6828 12.9151 15.7977 12.9151C17.9296 12.9151 19.6862 11.2254 19.7033 9.15473C19.7374 7.08405 17.9807 5.36125 15.8147 5.36125Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckInIcon",
};
</script>

<style></style>
