<template>
  <div class="bd-sidebar" v-if="!$route.meta.requiresVisitor">
    <div class="d-flex flex-column sidebar">
      <div class="sidebar-wrap">
        <ul class="nav nav-pills flex-column mb-auto">
          <li>
            <router-link to="/dashboard" class="nav-link">
              <DashboardIcon />
              <span>Dashboard</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'onboardings' }" class="nav-link">
              <OnboardIcon />

              <span>Onboarding screens</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/users" class="nav-link" dusk="users">
              <UserIcon />
              Users
            </router-link>
          </li>
          <li>
            <router-link to="/admins" class="nav-link" dusk="admins">
              <UserIcon />
              Admins
            </router-link>
          </li>
          <li>
            <router-link to="/pets" class="nav-link" dusk="pets">
              <PetIcon />
              Pets
            </router-link>
          </li>
          <li>
            <router-link to="/nutrition" class="nav-link">
              <NutritionIcon />
              Nutrition
            </router-link>
          </li>
          <li>
            <router-link to="/nutrition/categories" class="nav-link">
              <NutritionIcon />
              Nutrition Categories
            </router-link>
          </li>
          <li>
            <router-link to="/vaccine" class="nav-link">
              <VaccineUploadIcon />
              Vaccine Uploads
            </router-link>
          </li>
          <li>
            <router-link to="/first-aids" class="nav-link">
              <FirstAidIcon />
              First-Aid
            </router-link>
          </li>
          <li>
            <router-link to="/breeds" class="nav-link">
              <BreedIcon /> Breeds
            </router-link>
          </li>
          <li>
            <router-link
              to="/backend/merchants"
              class="nav-link"
              dusk="merchant"
            >
              <MerchantIcon />
              Merchant
            </router-link>
          </li>
          <li>
            <router-link
              to="/backend/merchant/tags"
              class="nav-link"
              dusk="merchant"
            >
              <MerchantIcon />
              Merchant Tags
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/new-section" class="nav-link">
              <NewSectionIcon />
              <span>New section</span>
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/check-in-place" class="nav-link">
              <CheckInPlacesIcon />
              <span> Check in place</span>
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/offers-management" class="nav-link">
              <OfferMgmtIcon />
              <span>Offers Management</span>
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/first-aid" class="nav-link">
              <FirstAidIcon />
              <span>First Aid</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/settings" class="nav-link">
              <CmsIcon />
              <span>
                Settings
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="/whats-new" class="nav-link">
          <whats-new-icon/>
              <span>
                Whats New
              </span>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/contact" class="nav-link">
              <ContactIcon />
              <span>Contact us</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BreedIcon from "./sidebarIcons/breedIcon.vue";
import DashboardIcon from "./sidebarIcons/dashboardIcon.vue";
import NutritionIcon from "./sidebarIcons/nutritionIcon.vue";
import OnboardIcon from "./sidebarIcons/onboardIcon.vue";
import PetIcon from "./sidebarIcons/petIcon.vue";
import UserIcon from "./sidebarIcons/userIcon.vue";
import VaccineUploadIcon from "./sidebarIcons/vaccineUploadIcon.vue";
import MerchantIcon from "./sidebarIcons/merchantIcon.vue";
import NewSectionIcon from "./sidebarIcons/newSectionIcon.vue";
import CheckInPlacesIcon from "./sidebarIcons/checkInPlacesIcon.vue";
import OfferMgmtIcon from "./sidebarIcons/offerMgmtIcon.vue";
import ContactIcon from "./sidebarIcons/contactIcon.vue";
import CmsIcon from "./sidebarIcons/cmsIcon.vue";
import FirstAidIcon from "./sidebarIcons/firstAidIcon.vue";
import WhatsNewIcon from './sidebarIcons/whatsNew.icon.vue';
export default {
  name: "Sidebar",
  components: {
    DashboardIcon,
    UserIcon,
    NutritionIcon,
    BreedIcon,
    PetIcon,
    VaccineUploadIcon,
    OnboardIcon,
    MerchantIcon,
    NewSectionIcon,
    CheckInPlacesIcon,
    OfferMgmtIcon,
    ContactIcon,
    CmsIcon,
    FirstAidIcon,
    WhatsNewIcon,
  },
};
</script>

<style>
</style>
