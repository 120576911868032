<template>
  <section class="section mt-5 setting-pet-insurance-section">
    <div class="panel">
      <div class="panel-header">
        <div class="title-wrapper">
          <h2 class="panel-title">Pet Insurance</h2>
        </div>
      </div>
      <div class="panel-body">
        <form @submit.prevent="submit">
          <v-app>
            <v-row>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label">Image</label>
                  <image-drag-drop
                    removeIcon="true"
                    class="image-drag-drop"
                    placeText="Drag & Drop here (PNG or JPEG) (575px * 280px)"
                    max="10"
                    :imageSource="form.image"
                    @imageDetails="checkImage"
                    @sizeValidation="checkSizeValidation"
                    @click="errors.image = null"
                    @input="errors.image = null"
                  ></image-drag-drop>
                  <small class="text-danger" v-if="errors.image"
                    >{{ errors.image[0] }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="label">Link</label>
                  <input
                    type="text"
                    class="form-control"
                    min="1"
                    placeholder=""
                    v-model="form.link"
                    @click="errors.link = null"
                  />
                  <small class="text-danger" v-if="errors.link"
                    >{{ errors.link[0] }}
                  </small>
                </div>
              </div>
              <div class="col col-12">
                <button class="btn btn-primary btn-save" :disabled="loading">
                  Save
                  <span class="loading-dots" v-if="loading"> </span>
                </button>
              </div>
            </v-row>
          </v-app>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import imageDragDrop from "../../subComponents/imageDragDrop.vue";
import { VueEditor } from "vue2-editor";
export default {
  name: "PetInsuranceComponent",
  components: { imageDragDrop, VueEditor },
  data() {
    return {
      loading: false,
      slug_id: 4,
      form: {
        id: "",
        image: null,
        link: "",
      },
      errors: {},
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        // ["blockquote",
        // "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          // {
          //     list: "check",
          // },
        ],
        // [
        //     {
        //         indent: "-1",
        //     },
        //     {
        //         indent: "+1",
        //     },
        // ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        [
          "link",
          "image",
          //  "video"
        ],
        // ["clean"], // remove formatting button
      ],
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    checkImage(data) {
      this.errors.image = null;
      this.form.image = data;
    },
    checkSizeValidation(data) {
      this.imageSizeValidation = data;
    },
    getData() {
      this.loading = true;
      axios
        .get(`/admin/pet_insurance`)
        .then(({ data }) => {
          // console.log(data?.data);
          if (data?.data != null) {
            this.form.id = data?.data?.id;
            this.form.image = data?.data?.image_web;
            this.form.link = data?.data?.link;
          }

          // if (data != "") {
          //   this.form.id = data.id;
          //   this.form.title_en = data.all_translations[0].title;
          //   this.form.title_zh = data.all_translations[1].title;
          //   this.form.description_en = data.all_translations[0].description;
          //   this.form.description_zh = data.all_translations[1].description;
          //   this.form.insurance_cover_en = data.all_translations[0].insurance_cover;
          //   this.form.insurance_cover_zh = data.all_translations[1].insurance_cover;
          // }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      axios
        .post(`/admin/pet_insurance/store`, this.form)
        .then(({ data }) => {
          this.loading = false;
          Toast.success();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  max-width: 1060px;
}

.form-group {
  margin-bottom: 20px;
  .label {
    margin-bottom: 8px;
  }
}

.btn-save {
  &:disabled {
    background-color: var(--primary-color);
  }
  .loading-dots {
    position: relative;
    top: 1px;
    left: unset;
  }
}
</style>
