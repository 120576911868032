<template>
    <section class="section section-mileston">
        <div class="panel">
            <div class="panel-body">
                <v-app class="v-app mt-5">
                    <v-data-table
                        :headers="activeHeaders"
                        :items="faqs"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        class="elevation-1"
                        hide-default-footer
                        :items-per-page="-1"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <div class="toolbar-left">
                                    <v-card-title>
                                        <v-toolbar-title class="panel-with">
                                            <h3 class="panel-title">Faq</h3>
                                        </v-toolbar-title>
                                    </v-card-title>
                                </div>
                                <div class="toolbar-right">
                                    <!-- Create/Edit Modal -->
                                    <v-dialog
                                        v-model="dialog"
                                        max-width="900px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <button
                                                class="btn btn-primary btn-add"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Add item
                                            </button>
                                        </template>
                                        <v-card class="add-categories">
                                            <div class="modal-head">
                                                <h3 class="modal-title">
                                                    {{ formTitle }}
                                                </h3>
                                            </div>

                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for=""
                                                                >Title(En)</label
                                                            >
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                :maxlength="max"
                                                                v-model="
                                                                    form.title_en
                                                                "
                                                                @click="
                                                                    errors.title_en =
                                                                        null
                                                                "
                                                                @input="
                                                                    errors.title_en =
                                                                        null
                                                                "
                                                                @keyup="
                                                                    lengthTitleEn
                                                                "
                                                            />
                                                            <small
                                                                class="char-limit"
                                                            >
                                                                {{
                                                                    titleLength_en
                                                                }}/{{ max }}
                                                            </small>
                                                            <small
                                                                class="text-danger"
                                                                v-if="
                                                                    errors.title_en
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .title_en[0]
                                                                }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for=""
                                                                >Title(Zh)</label
                                                            >
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                :maxlength="max"
                                                                v-model="
                                                                    form.title_zh
                                                                "
                                                                @click="
                                                                    errors.title_zh =
                                                                        null
                                                                "
                                                                @input="
                                                                    errors.title_zh =
                                                                        null
                                                                "
                                                                @keyup="
                                                                    lengthTitleZh
                                                                "
                                                            />
                                                            <small
                                                                class="char-limit"
                                                            >
                                                                {{
                                                                    titleLength_zh
                                                                }}/{{ max }}
                                                            </small>
                                                            <small
                                                                class="text-danger"
                                                                v-if="
                                                                    errors.title_zh
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .title_zh[0]
                                                                }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="vaccine"
                                                                >Content
                                                                (En)</label
                                                            >

                                                            <VueEditor
                                                                v-model="
                                                                    form.content_en
                                                                "
                                                                :editorToolbar="
                                                                    customToolbar
                                                                "
                                                                @click="
                                                                    errors.content_en =
                                                                        null
                                                                "
                                                                @input="
                                                                    errors.content_en =
                                                                        null
                                                                "
                                                            />
                                                            <small
                                                                class="text-danger"
                                                                v-if="
                                                                    errors.content_en
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .content_en[0]
                                                                }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="vaccine"
                                                                >Content
                                                                (Zh)</label
                                                            >

                                                            <VueEditor
                                                                v-model="
                                                                    form.content_zh
                                                                "
                                                                :editorToolbar="
                                                                    customToolbar
                                                                "
                                                                @click="
                                                                    errors.content_zh =
                                                                        null
                                                                "
                                                                @input="
                                                                    errors.content_zh =
                                                                        null
                                                                "
                                                            />
                                                            <small
                                                                class="text-danger"
                                                                v-if="
                                                                    errors.content_zh
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .content_zh[0]
                                                                }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <div class="actions">
                                                    <button
                                                        class="btn btn-secondary btn-cancel"
                                                        @click="close"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        class="btn btn-primary btn-save"
                                                        @click="save"
                                                        :disabled="loading"
                                                    >
                                                        Save
                                                        <span
                                                            class="loading-dots"
                                                            v-if="loading"
                                                        >
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </v-toolbar>
                            <!-- Delete Modals -->

                            <v-dialog v-model="dialogDelete" max-width="460px">
                                <v-card class="modal-actionDelete">
                                    <div class="modal-head">
                                        <v-card-title class="card-title"
                                            >Delete Faq</v-card-title
                                        >
                                    </div>
                                    <v-card-subtitle>
                                        Are you sure want to delete?
                                    </v-card-subtitle>
                                    <v-card-actions>
                                        <v-btn
                                            class="btn btn-secondary"
                                            text
                                            @click="closeDelete"
                                            >Cancel</v-btn
                                        >
                                        <v-btn
                                            class="btn btn-primary btn-sucess"
                                            text
                                            @click="deleteItemConfirm"
                                            >Yes, delete</v-btn
                                        >
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        <template #body="props" v-if="faqs.length > 0">
                            <draggable
                                tag="tbody"
                                :disabled="!allowDrag"
                                :move="onMoveCallback"
                                :clone="onCloneCallback"
                                v-bind="dragOptions"
                                @end="onDropCallback"
                                @change="changePosition"
                                v-model="faqs"
                                handle=".handle"
                            >
                                <data-table-row-handler
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                    :item="item"
                                    :headers="activeHeaders"
                                    :item-class="getClass(item)"
                                >
                                    <template v-slot:item.sn="{ item }">
                                        <span class="handle">
                                            <DragIcon />
                                            <!-- <span>{{ index + 1 }} </span> -->
                                        </span>
                                    </template>
                                    <template
                                        v-slot:item.all_translations[0]="{
                                            item,
                                        }"
                                    >
                                        <span v-if="item.all_translations[0]">
                                            {{ item.all_translations[0].title }}
                                        </span>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <div class="action">
                                            <button
                                                class="btn btn-view-text"
                                                @click="editItem(item)"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                class="btn btn-delete-text"
                                                @click="deleteItem(item)"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </template>
                                </data-table-row-handler>
                            </draggable>
                        </template>
                    </v-data-table>

                    <Pagination
                        :totalItems="pagination.totalItems + ' Faqs'"
                        :totalPages="pagination.totalPages"
                        :perPage="pagination.perPage"
                        :currentPage="pagination.currentPage"
                        @pagechanged="getFaqs"
                    ></Pagination>
                </v-app>
            </div>
        </div>
    </section>
</template>

<script>
import draggable from "vuedraggable";
import Pagination from "../../subComponents/pagination.vue";
import DataTableRowHandler from "../../modules/RowDragable/DataTableRowHandler.vue";
import DragIcon from "../../icons/drag.icon.vue";
import { VueEditor } from "vue2-editor";
export default {
    name: "faq-component",
    components: {
        draggable,
        Pagination,
        DataTableRowHandler,
        DragIcon,
        VueEditor,
    },
    data() {
        return {
            allowDrag: true,
            loading: false,
            modalCreate: false,
            dialogDelete: false,
            paginationOf: "categories",
            dialog: false,
            dialogDelete: false,
            loading: false,
            editedIndex: -1,
            faqs: [],
            max: "200",
            titleLength_en: "0",
            titleLength_zh: "0",

            customToolbar: [
                [
                    {
                        header: [false, 1, 2, 3, 4, 5, 6],
                    },
                ],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    {
                        align: "",
                    },
                    {
                        align: "center",
                    },
                    {
                        align: "right",
                    },
                    {
                        align: "justify",
                    },
                ],
                // ["blockquote",
                // "code-block"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                    // {
                    //     list: "check",
                    // },
                ],
                // [
                //     {
                //         indent: "-1",
                //     },
                //     {
                //         indent: "+1",
                //     },
                // ], // outdent/indent
                [
                    {
                        color: [],
                    },
                    {
                        background: [],
                    },
                ], // dropdown with defaults from theme
                [
                    "link",
                    //  "image",
                    //  "video"
                ],
                // ["clean"], // remove formatting button
            ],
            headers: [
                {
                    text: "S.N.",
                    value: "sn",
                    width: "36px",
                    sortable: false,
                },

                {
                    text: "Title",
                    align: "left",
                    sortable: false,
                    value: "all_translations[0]",
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                    align: "start",
                    width: "140px",
                },
            ],
            form: {
                id: "",
                title_en: "",
                title_zh: "",
                content_en: "",
                content_zh: "",
            },
            defaultItem: {
                id: "",
                title_en: "",
                title_zh: "",
                content_en: "",
                content_zh: "",
            },
            pagination: {
                totalItems: 0,
                totalPages: 1,
                perPage: 0,
                currentPage: 1,
            },
            deleteId: "",
            errors: {},
        };
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.titleLength_en = 0;
                this.titleLength_zh = 0;
            }
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Add Faq" : "Edit Faq";
        },
        activeHeaders() {
            return this.headers.filter((h) => {
                if (!this.allowDrag && h.value === "lock") {
                    return false;
                }
                return true;
            });
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    created() {
        const token = localStorage.getItem("access_token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
    },
    mounted() {
        this.getFaqs(this.pagination.currentPage);
    },
    methods: {
        getClass(item) {
            return item.calories > 500
                ? "cal-high"
                : item.calories > 400
                ? "cal-medium"
                : "cal-low";
        },
        onCloneCallback(item) {
            // Create a fresh copy of item
            const cloneMe = JSON.parse(JSON.stringify(item));
            return cloneMe;
        },
        onMoveCallback(evt, originalEvent) {
            const item = evt.draggedContext.element;
            const itemIdx = evt.draggedContext.futureIndex;
            //   console.log("onMoveCallback");

            return true;
        },
        onDropCallback(evt, originalEvent) {
            //   console.log("onDropCallback");
        },
        getFaqs(page) {
            this.loading = true;
            axios
                .get(`/admin/settings/faq?page=${this.pagination.currentPage}`)
                .then(({ data }) => {
                    this.paginationOf = "Faqa";
                    this.pagination.totalItems = data.total;
                    this.pagination.totalPages = data.last_page;
                    this.pagination.perPage = data.per_page;
                    this.pagination.currentPage = data.current_page;
                    this.faqs = data.data;
                    this.loading = false;
                })
                .catch((errors) => console.log(errors));
        },
        deleteItem(item) {
            this.deleteId = item.id;
            this.editedIndex = this.faqs.indexOf(item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.loading = true;
            axios
                .delete(`/admin/settings/faq/${this.deleteId}`)
                .then(({ data }) => {
                    this.faqs.splice(this.editedIndex, 1);
                    this.getFaqs(this.pagination.currentPage);
                    this.loading = false;
                    this.dialogDelete = false;
                    Toast.success();
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.errors;
                    this.dialogDelete = false;
                });
        },
        changePosition() {
            axios
                .post(`/admin/settings/faq/change-position`, this.faqs)
                .then(({ data }) => {
                    this.$toast.open({
                        message: "Faq order updated !",
                        type: "success",
                        duration: 3000,
                        dismissible: true,
                        position: "top-right",
                    });
                })
                .catch((error) => console.log(error));
        },

        close() {
            this.dialog = false;
            this.errors = {};
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.form = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (this.editedIndex > -1) {
                this.loading = true;
                axios
                    .put(`/admin/settings/faq/${this.form.id}`, this.form)
                    .then(({ data }) => {
                        this.loading = false;
                        this.close();
                        Toast.success();
                        this.getFaqs(this.pagination.currentPage);
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        this.loading = false;
                    });
            } else {
                this.loading = true;
                axios
                    .post(`/admin/settings/faq/create`, this.form)
                    .then(({ data }) => {
                        this.loading = false;
                        this.close();
                        Toast.success();
                        this.getFaqs(this.pagination.currentPage);
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        this.loading = false;
                    });
            }
        },
        editItem(item) {
            this.editedIndex = this.faqs.indexOf(item);
            this.form.id = item.id;
            this.form.title_en = item.all_translations[0].title;
            this.form.title_zh = item.all_translations[1].title;
            this.form.content_en = item.all_translations[0].content;
            this.form.content_zh = item.all_translations[1].content;
            this.dialog = true;
            this.titleLength_en = ("" + this.form.title_en.length).slice(-3);
            this.titleLength_zh = ("" + this.form.title_zh.length).slice(-3);
        },
        lengthTitleEn() {
            this.titleLength_en = ("" + this.form.title_en.length).slice(-3);
        },
        lengthTitleZh() {
            this.titleLength_zh = ("" + this.form.title_zh.length).slice(-3);
        },
    },
};
</script>

<style lang="scss" scoped>
.char-limit {
    text-align: right;
    display: block;
    color: var(--primary-color);
}
</style>
