<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="30"
    viewBox="0 0 27 30"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3376 0C4.45141 6.825 0 12.72 0 17.7C0 25.17 6.33534 30 13.3376 30C20.3398 30 26.6751 25.17 26.6751 17.7C26.6751 12.72 22.2237 6.825 13.3376 0ZM18.3391 24V21H8.33597V24H18.3391ZM11.6704 13.5V10.5H15.0047V13.5H18.3391V16.5H15.0047V19.5H11.6704V16.5H8.33597V13.5H11.6704Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "DonorIcon",
};
</script>
