<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-user">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="categories"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
                rowKey
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">
                            Nutrition Categories
                          </h3></v-toolbar-title
                        >
                        <div class="search-toolbar-area">
                          <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <!-- Create/Edit Modal -->
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <button
                            class="btn btn-primary btn-add"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Add item
                          </button>
                        </template>
                        <v-card class="add-categories">
                          <div class="modal-head">
                            <h3 class="modal-title">{{ formTitle }}</h3>
                          </div>

                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Category name(En)</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="editedItem.name_en"
                                    @click="errors.name_en = null"
                                    @input="errors.name_en = null"
                                  />
                                  <small
                                    class="text-danger"
                                    v-if="errors.name_en"
                                  >
                                    {{ errors.name_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Category name(zh)</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="editedItem.name_zh"
                                    @click="errors.name_zh = null"
                                    @input="errors.name_zh = null"
                                  />
                                  <small
                                    class="text-danger"
                                    v-if="errors.name_zh"
                                  >
                                    {{ errors.name_zh[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="vaccine">Image</label>
                                  <ImageDragDrap
                                    removeIcon="true"
                                    :maxSize="4"
                                    :imageSource="editedItem.image_web"
                                    max="10"
                                    class="image-drag-drop"
                                    placeText="Drag & Drop here (PNG or JPEG)"
                                    @imageDetails="checkImage"
                                    @click="errors.image = null"
                                    @input="errors.image = null"
                                  ></ImageDragDrap>
                                  <small
                                    class="text-danger"
                                    v-if="errors.image"
                                  >
                                    {{ errors.image[0] }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <div class="actions">
                              <button
                                class="btn btn-secondary btn-cancel"
                                @click="close"
                              >
                                Cancel
                              </button>
                              <button
                                class="btn btn-primary btn-save"
                                @click="save"
                                :disabled="loading"
                              >
                                Save
                                <span class="loading-dots" v-if="loading">
                                </span>
                              </button>
                            </div>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-toolbar>
                  <!-- Delete Modals -->

                  <v-dialog v-model="dialogDelete" max-width="460px">
                    <v-card class="modal-actionDelete">
                      <div class="modal-head">
                        <v-card-title class="card-title"
                          >Delete Category</v-card-title
                        >
                      </div>
                      <v-card-subtitle>
                        Are you sure want to delete?
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn
                          class="btn btn-secondary"
                          text
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          class="btn btn-primary btn-sucess"
                          text
                          @click="deleteItemConfirm"
                          >Yes, delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.image_web="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="
                        item.image_web !== null
                          ? item.image_web
                          : '/images/pet-deafult.png'
                      "
                      class="border-img"
                    />
                  </div>
                </template>
                <template v-slot:item.all_translations[0]="{ item }">
                  <span v-if="item.all_translations[0]">
                    {{ item.all_translations[0].name }}
                  </span>
                </template>
                <template v-slot:item.all_translations[1]="{ item }">
                  <span v-if="item.all_translations[1]">
                    {{ item.all_translations[1].name }}
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <button class="btn btn-view-text" @click="editItem(item)">
                      Edit
                    </button>
                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                    >
                      Delete
                    </button>
                  </div>
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Categories'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getNutritionCategories"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../subComponents/pagination.vue";
import ImageDragDrap from "../subComponents/imageDragDrop.vue";
export default {
  name: "NutritionCategories",
  components: {
    Pagination,
    ImageDragDrap,
  },
  data: () => ({
    pagination: {
      totalItems: 0,
      totalPages: 1,
      perPage: 0,
      currentPage: 1,
    },
    paginationOf: "categories",
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      {
        text: "S.N.",
        align: "left",
        sortable: false,
        value: "sn",
        width: "30px",
      },
      {
        text: "image",
        align: "center",
        sortable: false,
        value: "image_web",
      },
      {
        text: "Name EN",
        align: "left",
        sortable: true,
        value: "all_translations[0]",
      },
      {
        text: "Name ZH",
        align: "left",
        sortable: true,
        value: "all_translations[1]",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      image: "",
      image_web: "",
      name_en: "",
      name_zh: "",
    },
    defaultItem: {
      id: null,
      image: "",
      image_web: "",
      name_en: "",
      name_zh: "",
    },
    search: null,
    awaitingSearch: false,
    errors: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Category" : "Edit Category";
    },
  },

  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getNutritionCategories(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            axios
              .get(
                "/admin/nutrition/category/search?page=" +
                  this.pagination.currentPage,
                {
                  params: { search: this.search },
                }
              )
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.totalItems = data.total;
                this.pagination.totalPages = data.last_page;
                this.pagination.perPage = data.per_page;
                this.pagination.currentPage = data.current_page;
                this.categories = data.data;
                this.loading = false;
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getNutritionCategories(this.pagination.currentPage);
  },

  methods: {
    getNutritionCategories(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        axios
          .get(
            `/admin/nutrition/category/search?page=${this.pagination.currentPage}`,
            {
              params: { search: this.search },
            }
          )
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.categories = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(
            `/admin/nutrition/categories?page=${this.pagination.currentPage}`
          )
          .then(({ data }) => {
            this.paginationOf = "categories";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.categories = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      }
    },

    editItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.image = item.image;
      this.editedItem.image_web = item.image_web;
      this.editedItem.name_en = item.all_translations[0].name;
      this.editedItem.name_zh = item.all_translations[1].name;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.image = item.image;
      this.editedItem.image_web = item.image_web;
      this.editedItem.name_en = item.all_translations[0].name;
      this.editedItem.name_zh = item.all_translations[1].name;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      axios
        .delete(`/admin/nutrition/category/${this.editedItem.id}`)
        .then(({ data }) => {
          this.categories.splice(this.editedIndex, 1);
          this.closeDelete();
          Toast.success();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
      this.errors = {};
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        axios
          .put(
            `/admin/nutrition/category/${this.editedItem.id}`,
            this.editedItem
          )
          .then(({ data }) => {
            this.loading = false;
            this.close();
            Toast.success();
            this.getNutritionCategories(this.pagination.currentPage);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.loading = false;
          });
      } else {
        this.loading = true;
        axios
          .post(`/admin/nutrition/category/create`, this.editedItem)
          .then(({ data }) => {
            this.loading = false;
            this.close();
            Toast.success();
            this.getNutritionCategories(this.pagination.currentPage);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.loading = false;
          });
      }
    },
    checkImage(data) {
      this.errors.image = null;
      this.editedItem.image = data;
    },
  },
};
</script>
