import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
// axios.defaults.baseURL = 'http://localhost/'

export const store = new Vuex.Store({
    state: {
        token: localStorage.getItem('access_token') || null,
        user_id: localStorage.getItem('user_id') || null,
        user_name: localStorage.getItem('user_name') || null,
        user_image: localStorage.getItem('user_image') || null,
        is_admin: localStorage.getItem('is_admin') || null,
    },
    getters: {
        loggedIn(state) {
            return state.token !== null
        },
    },
    mutations: {

        retrieveToken(state, token) {
            state.token = token
        },
        retriveUser(state, user) {
            state.user_id = user.id
            state.user_name = user.name
            state.user_image = user.image_web
            state.is_admin = user.is_admin
        },
        destroyToken(state) {
            state.token = null
            state.user_id = null
            state.user_name = null
            state.user_image = null
            state.is_admin = null
        },
    },
    actions: {
        destroyToken(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

            if (context.getters.loggedIn) {
                return new Promise((resolve, reject) => {
                    axios.get('/admin/logout')
                        .then(response => {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user_id')
                            context.commit('destroyToken')
                            resolve(response)
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                            Toast.fire({
                                icon: 'success',
                                title: 'Logged out successfully'
                            })
                        })
                        .catch(error => {
                            localStorage.removeItem('access_token')
                            localStorage.removeItem('user_id')
                            context.commit('destroyToken')
                            reject(error)
                        })
                })
            }
        },
        destroyUnAuthorizedToken(context) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user_id')
            context.commit('destroyToken')
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: "error",
                title: "Unauthorized Logged out",
            })
        },
        retrieveToken(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('/admin/login', {
                    email: credentials.email,
                    password: credentials.password,
                })
                    .then(response => {
                        // console.log(response)
                        const token = response.data.token
                        const refreshToken = response.data.refresh_token;
                        const user = response.data.user
                        localStorage.setItem('access_token', token)
                        localStorage.setItem("refresh_token", refreshToken);
                        localStorage.setItem('user_id', user.id)
                        localStorage.setItem('user_name', user.name)
                        localStorage.setItem('user_image', user.image)
                        localStorage.setItem('is_admin', user.is_admin)
                        context.commit('retrieveToken', token)
                        context.commit('retriveUser', user)
                        resolve(response)
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'Signed in successfully'
                        })
                        // console.log(response);
                    })
                    .catch((error) => {
                        if (error.response.status == 422) {
                            reject(error.response.data)
                        }

                    })
            })
        },
    }
})
