<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-user">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                :headers="headers"
                :items="users"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">
                            Users management
                          </h3></v-toolbar-title
                        >
                        <div class="search-toolbar-area">
                          <v-text-field
                            name="search"
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <div class="filter-select">
                        <FilterByDate
                          :newFilterOption="newFilterOption"
                          @changedFilterOption="getChangedFilterOption"
                        ></FilterByDate>
                      </div>
                    </div>
                    <v-dialog v-model="showPets" max-width="360px">
                      <v-card class="show-pet">
                        <v-card-title>
                          <span class="user-title"
                            >{{ currentGuardian }}'s
                            {{
                              currentGuardianPetCount > 1 ? "Pets" : "Pet"
                            }}</span
                          >
                        </v-card-title>

                        <v-card-text>
                          <ul class="pet-lists">
                            <li
                              class="pet-list"
                              v-for="(pet, i) in pets"
                              :key="i"
                            >
                              <div class="list-wrap">
                                <figure class="thumb-wrap">
                                  <img
                                    :src="
                                      pet.image_web !== null
                                        ? pet.image_web
                                        : '/images/pet-deafult.png'
                                    "
                                    alt="pet image"
                                  />
                                </figure>
                                <div class="content-wrap">
                                  <div class="text-wrap">
                                    <h4 class="pet-name">
                                      {{ pet.name }}
                                    </h4>
                                    <label class="pet-breed">
                                      {{ petType(pet.pet_type) }}
                                    </label>
                                  </div>
                                  <div class="action">
                                    <router-link
                                      class="link"
                                      :to="/pet/ + pet.id"
                                      >View more <span class="arrow-icon"></span
                                    ></router-link>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <!-- template form modal -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <h3 class="card-title">{{ formTitle }}</h3>
                        </v-card-title>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            class="btn btn-primary btn-save"
                            color=""
                            text
                            @click="save"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            class="btn btn-secondary btn-cancel"
                            color=""
                            text
                            @click="close"
                          >
                            Cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <!-- modal for Delete -->
                    <v-dialog v-model="dialogDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title">Delete</v-card-title>
                        </div>
                        <v-card-subtitle>
                          Are you sure want to delete?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="deleteItemConfirm"
                            >Yes, delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- modal for Force Delete -->
                    <v-dialog v-model="dialogForceDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title"
                            >Delete User</v-card-title
                          >
                        </div>
                        <v-card-subtitle>
                          Are you sure want to delete user permanently?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeForceDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="forceDeleteItemConfirm"
                            >Yes, delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <!-- modal for show -->
                    <v-dialog v-model="userShow" max-width="830px">
                      <v-card class="user--show-modal">
                        <div class="modal-head">
                          <div class="modal-left">
                            <v-card-title class="card-title"
                              >User Details</v-card-title
                            >
                            <span class="status active">{{
                              userDetails.status
                            }}</span>
                            <span class="location">Home Kong</span>
                            <span class="reg-date"
                              >Registered:
                              {{
                                userDetails.email_verified_at
                                  | moment("D MMMM, YYYY")
                              }}
                            </span>
                          </div>
                          <div class="modal-right">
                            <div class="action">
                              <button type="button" class="btn btn-text">
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-5">
                                  <div class="image-area">
                                    <div class="form-wrap">
                                      <label for="">Image</label>
                                      <div class="image-wrap">
                                        <img
                                          :src="
                                            userDetails.image_web !== null
                                              ? userDetails.image_web
                                              : '/images/pet-deafult.png'
                                          "
                                          alt="user image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-7">
                                  <div class="text-wrap">
                                    <div class="form-wrap">
                                      <label for="">Full Name: </label>
                                      <span>{{ userDetails.name }}</span>
                                    </div>
                                    <div class="form-wrap">
                                      <label for="">Phone: </label
                                      ><span> 11234567809</span>
                                    </div>
                                    <div class="form-wrap">
                                      <label for="">Email: </label>
                                      <span>{{ userDetails.email }} </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="pet-details" v-if="userPetCount != 0">
                            <div class="primary-detail">
                              <v-card-title>
                                <span class="user-title"
                                  >{{ userDetails.name }}'s Pet<template
                                    v-if="userPetCount > 1"
                                    >s</template
                                  >
                                  ({{ userPetCount }})
                                </span>
                              </v-card-title>
                              <ul class="pet-lists">
                                <li
                                  class="pet-list"
                                  v-for="(pet, i) in userDetails.pets"
                                  :key="i"
                                >
                                  <div class="list-wrap">
                                    <figure class="thumb-wrap">
                                      <img
                                        :src="
                                          pet.image_web !== null
                                            ? pet.image_web
                                            : '/images/pet-deafult.png'
                                        "
                                        alt="pet image"
                                      />
                                    </figure>
                                    <div class="content-wrap">
                                      <div class="text-wrap">
                                        <h4 class="pet-name">
                                          {{ pet.name }}
                                        </h4>
                                        <label class="pet-breed">
                                          {{ petType(pet.pet_type) }}
                                        </label>
                                      </div>
                                      <div class="action">
                                        <router-link
                                          class="link"
                                          :to="/pet/ + pet.id"
                                          >View more
                                          <span class="arrow-icon"></span
                                        ></router-link>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <!-- end primary details -->
                            <div class="secondary-detail">
                              <v-card-title>
                                <span class="user-title"
                                  >Guardian Pets (1)
                                </span>
                              </v-card-title>
                              <ul class="pet-lists">
                                <li
                                  class="pet-list"
                                  v-for="(pet, i) in userDetails.pets"
                                  :key="i"
                                >
                                  <div class="list-wrap" v-if="i == 0">
                                    <figure class="thumb-wrap">
                                      <img
                                        :src="
                                          pet.image_web !== null
                                            ? pet.image_web
                                            : '/images/pet-deafult.png'
                                        "
                                        alt="pet image"
                                      />
                                    </figure>
                                    <div class="content-wrap">
                                      <div class="text-wrap">
                                        <h4 class="pet-name">
                                          {{ pet.name }}
                                        </h4>
                                        <label class="pet-breed">
                                          {{ petType(pet.pet_type) }}
                                        </label>
                                      </div>
                                      <div class="action">
                                        <router-link
                                          class="link"
                                          :to="/pet/ + pet.id"
                                          >View more
                                          <span class="arrow-icon"></span
                                        ></router-link>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.image_web="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="
                        item.image_web !== null
                          ? item.image_web
                          : '/images/user-deafult.png'
                      "
                      class="border-img"
                    />
                  </div>
                </template>
                <template v-slot:item.name="{ item }">
                  <a
                    href="javascript:void(0);"
                    class="name link"
                    @click="userDetail(item)"
                  >
                    {{ item.name }}
                  </a>
                </template>
                <template v-slot:item.email_verified_at="{ item }">
                  <div class="date">
                    {{ item.email_verified_at | formatDate }}

                    <!-- {{ item.email_verified_at.toString() }} -->
                    <!-- {{ moment('2022-03-07T23:27:42.000000Z').format("YYYY-MM-DD HH:mm:ss") }} -->
                  </div>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip class="status" :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.contact="{ item }">
                  <span>
                    {{ item.contact }}
                    <br />
                    <a :href="'mailto:' + item.email" class="link-email">
                      {{ item.email }}</a
                    >
                  </span>
                </template>
                <template v-slot:item.pets="{ item }">
                  <a
                    href="javascript:void(0);"
                    class="link"
                    @click="petDetail(item)"
                  >
                    {{ item.pets_count }}
                  </a>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <!-- <button class="btn btn-edit" @click="editItem(item)">
                      <div class="text-animation"></div>
                    </button> -->
                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                      v-if="item.status == 'active'"
                    >
                      Delete
                      <div class="text-animation"></div>
                    </button>
                    <button
                      class="btn btn-delete-text"
                      @click="forceDeleteItem(item)"
                    >
                      Force Delete
                      <div class="text-animation"></div>
                    </button>
                  </div>
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Users'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getUsers"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FilterByDate from "../subComponents/filterByDate.vue";
import Pagination from "../subComponents/pagination.vue";
import moment from "moment";
export default {
  name: "users",
  components: {
    FilterByDate,
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "users",
      users: [],
      loading: true,
      dialog: false,
      showPets: false,
      userShow: false,
      dialogDelete: false,
      dialogForceDelete: false,
      newFilterOption: null,
      headers: [
        {
          text: "S.N.",
          align: "start",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "User Id",
          align: "start",
          sortable: true,
          value: "id",
          width: "105px",
        },
        {
          text: "Image",
          value: "image_web",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          align: "start",
        },
        // {
        //   text: "Location",
        //   value: "location",
        //   sortable: true,
        // },
        {
          text: "Registration Date",
          value: "email_verified_at",
          sortable: true,
          align: "start",
          width: "185px",
        },
        {
          text: "Registration type",
          value: "auth_type",
          sortable: true,
          width: "185px",
        },
        {
          text: "Device Type",
          value: "platform",
          sortable: true,
          width: "145px",
        },
        {
          text: "Associated Pets",
          value: "pets",
          sortable: true,
          width: "180px",
          align: "left",
        },
        { text: "Contact", value: "contact", sortable: false },
        { text: "Status", value: "status", sortable: true },
        { text: "Actions", value: "actions", sortable: false, align: "start" },
      ],
      editedIndex: -1,
      editedItem: {
        id: "",
        img_name: "",
        name: "",
      },
      defaultItem: {
        id: "",
        img_name: "",
        name: "",
      },
      pets: [],
      userDetails: [],
      userPetCount: null,
      currentGuardian: "",
      currentGuardianPetCount: 0,
      search: null,
      awaitingSearch: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getUsers(this.pagination.currentPage);
  },
  watch: {
    search: function (val) {
      this.selectedDateFilter = null;
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getUsers(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            this.newFilterOption = null;
            //  this.pagination.currentPage = 1;
            axios
              .get(`/admin/user/search?page=${this.pagination.currentPage}`, {
                params: { search: this.search },
              })
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.currentPage = data.current_page;
                this.pagination.totalPages = data.last_page;
                this.users = data.data;
                this.pagination.totalItems = data.total;
                this.loading = false;
                // console.log(data);
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogForceDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    petDetail(item) {
      console.log(item);
      this.currentGuardian = item.name;
      this.currentGuardianPetCount = item.pets_count;
      if (this.currentGuardianPetCount > 0) {
        this.pets = item.pets;
        this.showPets = true;
      } else {
        this.showPets = false;
      }
    },
    userDetail(item) {
      //   console.log(item.email_verified_at);
      this.userPetCount = item.pets.length;
      this.userDetails = item;
      this.userShow = true;
    },
    getUsers(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        axios
          .get(`/admin/user/search?page=${this.currentPage}`, {
            params: { search: this.search },
          })
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.currentPage = data.current_page;
            this.pagination.totalPages = data.last_page;
            this.users = data.data;
            this.pagination.totalItems = data.total;
            this.loading = false;
            // console.log(data);
          })
          .catch((errors) => console.log(errors));
      } else if (this.paginationOf == "filterby") {
        axios
          .get(
            `/admin/pet/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "filterby";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.users = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(`/admin/user?page=${this.pagination.currentPage}`)
          .then(({ data }) => {
            this.paginationOf = "users";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.users = data.data;
            this.loading = false;
            //  console.log(this.users);
          })
          .catch((errors) => console.log(errors));
      }
    },
    getChangedFilterOption(option) {
      this.newFilterOption = option;
      this.loading = true;
      axios
        .get(
          `/admin/user/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
        )
        .then(({ data }) => {
          this.paginationOf = "filterby";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.users = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    forceDeleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForceDelete = true;
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeForceDelete() {
      this.dialogForceDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
    deleteItemConfirm() {
      axios
        .delete("/admin/user/" + this.editedItem.id)
        .then(this.getUsers(this.pagination.currentPage), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },
    forceDeleteItemConfirm() {
      axios
        .delete(`/admin/delete/user/${this.editedItem.id}`)
        .then(this.users.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeForceDelete();
    },
    getColor(statusGet) {
      //   console.log(statusGet);
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else return "hidden";
    },
    petType(type) {
      if (type == 0) {
        return "Dog";
      } else if (type == 1) {
        return "Cat";
      } else {
        return;
      }
    },
  },
};
</script>

<style>
</style>

