<template>
<svg fill="#181818" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#181818"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0m.056 1355.181 56.471.113h-56.47v-.113ZM752.64 409.65c83.69-64.715 191.661-86.4 296.696-59.294 114.862 29.703 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.303-35.464 261.459-45.515 77.026-121.186 133.948-207.586 156.084-13.779 3.502-27.783 14.796-27.783 31.85v91.708H903.529v-91.708c0-66.07 46.306-124.123 112.716-141.29 57.6-14.682 107.971-52.63 138.353-104.018 30.833-52.292 39.19-114.749 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.184-143.548-3.953-199.341 39.303-55.68 43.144-87.642 108.311-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01Zm207.416 832.704c-62.343 0-112.94 50.71-112.94 112.941 0 62.23 50.597 112.941 112.94 112.941 62.231 0 112.942-50.71 112.942-112.94 0-62.231-50.71-112.942-112.942-112.942Z" fill-rule="evenodd"></path> </g></svg>
</template>

<script>
export default {
  name:"WhatsNewIcon"

}
</script>

