<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-mileston section-whats-new">
        <div class="panel">
          <div class="panel-body">
            <v-app class="v-app">
              <v-data-table :headers="activeHeaders" :items="whatNew" :loading="loading"
                loading-text="Loading... Please wait" class="elevation-1" hide-default-footer :items-per-page="-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">Whats new</h3>
                        </v-toolbar-title>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <!-- Create/Edit Modal -->
                      <v-dialog v-model="dialog" max-width="900px">
                        <template v-slot:activator="{ on, attrs }">
                          <button class="btn btn-primary btn-add" v-bind="attrs" v-on="on">
                            Add item
                          </button>
                        </template>
                        <v-card class="add-categories">
                          <div class="modal-head">
                            <h3 class="modal-title">
                              {{ formTitle }}
                            </h3>
                          </div>

                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Title(En)</label>
                                  <input type="text" class="form-control" :maxlength="max" v-model="form.title_en"
                                    @click="errors.title_en = null" @input="errors.title_en = null"
                                    @keyup="lengthTitleEn" />
                                  <small class="char-limit">
                                    {{ titleLength_en }}/{{ max }}
                                  </small>
                                  <small class="text-danger" v-if="errors.title_en">
                                    {{ errors.title_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Title(Zh)</label>
                                  <input type="text" class="form-control" :maxlength="max" v-model="form.title_zh"
                                    @click="errors.title_zh = null" @input="errors.title_zh = null"
                                    @keyup="lengthTitleZh" />
                                  <small class="char-limit">
                                    {{ titleLength_zh }}/{{ max }}
                                  </small>
                                  <small class="text-danger" v-if="errors.title_zh">
                                    {{ errors.title_zh[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="vaccine">Content (En)</label>

                                  <VueEditor v-model="form.description_en" :editorToolbar="customToolbar"
                                    @click="errors.description_en = null" @input="errors.description_en = null" />
                                  <small class="text-danger" v-if="errors.description_en">
                                    {{ errors.description_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="vaccine">Content (Zh)</label>

                                  <VueEditor v-model="form.description_zh" :editorToolbar="customToolbar"
                                    @click="errors.description_zh = null" @input="errors.description_zh = null" />
                                  <small class="text-danger" v-if="errors.description_zh">
                                    {{ errors.description_zh[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Image *</label>
                                  <ImageDragDrap removeIcon="true" :maxSize="4" :imageSource="form.image" max="10"
                                    class="image-drag-drop" placeText="Drag & Drop here (PNG or JPEG)"
                                    @imageDetails="checkImage" @click="errors.image = null" @input="errors.image = null">
                                  </ImageDragDrap>
                                  <small class="text-danger" v-if="errors.image">
                                    {{ errors.image[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Icon *</label>
                                  <ImageDragDrap removeIcon="true" :maxSize="4" :imageSource="form.icon" max="10"
                                    class="image-drag-drop" placeText="Drag & Drop here (PNG or JPEG)"
                                    @imageDetails="checkIconImage" @click="errors.icon = null"
                                    @input="errors.icon = null"></ImageDragDrap>
                                  <small class="text-danger" v-if="errors.icon">
                                    {{ errors.icon[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group color_picker">
                                  <label for="">Color </label>
                                  <input type="text" class="form-control" v-model="form.color"
                                    @click="errors.color = null" @input="errors.color = null" />
                                  <v-input-colorpicker v-model="form.color" />
                                  <small class="text-danger" v-if="errors.color">
                                    {{ errors.color[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="">Type</label>
                                  <v-select :items="['Blood donation page', 'link']" item-text="name" item-value="code"
                                    :clearable="false" outlined v-model="form.type"></v-select>
                                  <small class="text-danger" v-if="errors.type">
                                    {{ errors.type[0] }}
                                  </small>
                                </div>
                              </div>

                              <div class="col-md-6" v-if="form.type === 'link'">
                                <div class="form-group">
                                  <label for="">Link </label>
                                  <input type="text" class="form-control" v-model="form.link" @click="errors.link = null"
                                    @input="errors.link = null" />

                                  <small class="text-danger" v-if="errors.link">
                                    {{ errors.link[0] }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <div class="actions">
                              <button class="btn btn-secondary btn-cancel" @click="close">
                                Cancel
                              </button>
                              <button class="btn btn-primary btn-save" @click="save" :disabled="loading">
                                Save
                                <span class="loading-dots" v-if="loading">
                                </span>
                              </button>
                            </div>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-toolbar>
                  <!-- Delete Modals -->

                  <v-dialog v-model="dialogDelete" max-width="460px">
                    <v-card class="modal-actionDelete">
                      <div class="modal-head">
                        <v-card-title class="card-title">Delete Whats new</v-card-title>
                      </div>
                      <v-card-subtitle>
                        Are you sure want to delete?
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn class="btn btn-secondary" text @click="closeDelete">Cancel</v-btn>
                        <v-btn class="btn btn-primary btn-sucess" text @click="deleteItemConfirm">Yes, delete</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>

                <template #body="props" v-if="whatNew.length > 0">
                  <draggable tag="tbody" :disabled="!allowDrag" :move="onMoveCallback" :clone="onCloneCallback"
                    v-bind="dragOptions" @end="onDropCallback" @change="changePosition" v-model="whatNew"
                    handle=".handle">
                    <data-table-row-handler v-for="(item, index) in props.items" :key="index" :item="item"
                      :headers="activeHeaders" :item-class="getClass(item)">
                      <template v-slot:item.sn="{ item }">
                        <span class="handle">
                          <DragIcon />
                          <!-- <span>{{ index + 1 }} </span> -->
                        </span>
                      </template>
                      <template v-slot:item.all_translations[0]="{ item }">
                        <span v-if="item.all_translations[0]">
                          {{ item.all_translations[0].title }}
                        </span>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <div class="action">
                          <button class="btn btn-view-text" @click="editItem(item)">
                            Edit
                          </button>
                          <button class="btn btn-delete-text" @click="deleteItem(item)">
                            Delete
                          </button>
                        </div>
                      </template>
                    </data-table-row-handler>
                  </draggable>
                </template>
              </v-data-table>

              <Pagination :totalItems="pagination.totalItems + 'whats new'" :totalPages="pagination.totalPages"
                :perPage="pagination.perPage" :currentPage="pagination.currentPage" @pagechanged="getWhatsNew">
              </Pagination>
            </v-app>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import draggable from "vuedraggable";
import DataTableRowHandler from "../modules/RowDragable/DataTableRowHandler.vue";
import DragIcon from "../icons/drag.icon.vue";
import { VueEditor } from "vue2-editor";
import Pagination from "../subComponents/pagination.vue";
import ImageDragDrap from "../subComponents/imageDragDrop.vue";
import InputColorPicker from "vue-native-color-picker";

export default {
  components: {
    draggable,
    Pagination,
    VueEditor,
    DataTableRowHandler,
    DragIcon,
    ImageDragDrap,
    "v-input-colorpicker": InputColorPicker,
  },
  name: "WhatsNew",

  data() {
    return {
      allowDrag: true,
      loading: false,
      modalCreate: false,
      dialogDelete: false,
      paginationOf: "categories",
      dialog: false,
      dialogDelete: false,
      loading: false,
      editedIndex: -1,
      whatNew: [],
      max: "200",
      titleLength_en: "0",
      titleLength_zh: "0",
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],

        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },

        ],

        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        [
          "link",

        ],

      ],
      headers: [
        {
          text: "S.N.",
          value: "sn",
          width: "36px",
          sortable: false,
        },

        {
          text: "Title",
          align: "left",
          sortable: false,
          value: "all_translations[0]",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
          width: "140px",
        },
      ],

      form: {
        id: "",
        title_en: "",
        title_zh: "",
        description_en: "",
        description_zh: "",
        type: "Blood donation page",
        image: "",
        icon: "",
        color: "#cccccc",
        link: "",
      },
      defaultItem: {
        id: "",
        title_en: "",
        title_zh: "",
        description_en: "",
        description_zh: "",
        type: "Blood donation page",
        image: "",
        icon: "",
        color: "#cccccc",
        link: "",
      },
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },

      deleteId: "",
      errors: {},
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.titleLength_en = 0;
        this.titleLength_zh = 0;
      }
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Whats new" : "Edit Whats new";
    },
    activeHeaders() {
      return this.headers.filter((h) => {
        if (!this.allowDrag && h.value === "lock") {
          return false;
        }
        return true;
      });
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getWhatsNew(this.pagination.currentPage);
  },
  methods: {
    getClass(item) {
      return item.calories > 500
        ? "cal-high"
        : item.calories > 400
          ? "cal-medium"
          : "cal-low";
    },
    onCloneCallback(item) {
      // Create a fresh copy of item
      const cloneMe = JSON.parse(JSON.stringify(item));
      return cloneMe;
    },
    onMoveCallback(evt, originalEvent) {
      const item = evt.draggedContext.element;
      const itemIdx = evt.draggedContext.futureIndex;
      //   console.log("onMoveCallback");

      return true;
    },
    onDropCallback(evt, originalEvent) {
      //   console.log("onDropCallback");
    },
    getWhatsNew(page) {
      this.loading = true;
      axios
        .get(`/admin/whatsnew?page=${this.pagination.currentPage}`)
        .then(({ data }) => {
          this.paginationOf = "Faqa";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.whatNew = data.data;
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    deleteItem(item) {
      this.deleteId = item.id;
      this.editedIndex = this.whatNew.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.loading = true;
      axios
        .delete(`/admin/whatsnew/${this.deleteId}/delete`)
        .then(({ data }) => {
          this.whatNew.splice(this.editedIndex, 1);
          this.getWhatsNew(this.pagination.currentPage);
          this.loading = false;
          this.dialogDelete = false;
          Toast.success();
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.dialogDelete = false;
        });
    },
    changePosition() {
      axios
        .post(`/admin/whatsnew/change-position`, this.whatNew)
        .then(({ data }) => {
          this.$toast.open({
            message: "Faq order updated !",
            type: "success",
            duration: 3000,
            dismissible: true,
            position: "top-right",
          });
        })
        .catch((error) => console.log(error));
    },

    close() {
      this.dialog = false;
      this.errors = {};
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        axios
          .put(`/admin/whatsnew/${this.form.id}/update`, this.form)
          .then(({ data }) => {
            this.loading = false;
            this.close();
            Toast.success();
            this.getWhatsNew(this.pagination.currentPage);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.loading = false;
          });
      } else {
        this.loading = true;
        axios
          .post(`/admin/whatsnew/store`, this.form)
          .then(({ data }) => {
            this.loading = false;
            this.close();
            Toast.success();
            this.getWhatsNew(this.pagination.currentPage);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            this.loading = false;
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.whatNew.indexOf(item);
      this.form.id = item.id;
      this.form.title_en = item.all_translations[0].title;
      this.form.title_zh = item.all_translations[1].title;
      this.form.description_en = item.all_translations[0].description;
      this.form.description_zh = item.all_translations[1].description;
      this.form.image =  item.image_web;
      this.form.icon = item.icon_web;
      this.form.color = item.color;
      this.form.type = item.type;
      this.form.link = item.link;
      this.dialog = true;
      this.titleLength_en = ("" + this.form.title_en.length).slice(-3);
      this.titleLength_zh = ("" + this.form.title_zh.length).slice(-3);
    },
    lengthTitleEn() {
      this.titleLength_en = ("" + this.form.title_en.length).slice(-3);
    },
    lengthTitleZh() {
      this.titleLength_zh = ("" + this.form.title_zh.length).slice(-3);
    },
    checkImage(data) {
      this.errors.image = null;
      this.form.image = data;
    },
    checkIconImage(data) {
      this.errors.icon = null;
      this.form.icon = data;
    },
  },
};
</script>
