// console.log("Admin SPA");
require("./bootstrap");

// import { createApp } from 'vue'
import Vue from "vue";
// Vuetify
import vuetify from "./plugins/vuetify";

//Import router
import VueRouter from "vue-router";
Vue.use(VueRouter);

//Import vue moment
import VueMoment from "vue-moment";
Vue.use(VueMoment);

// Import Sweetalert2
import Swal from "sweetalert2";
window.Swal = Swal;

// Import Toaster Class
import Toast from "./Helpers/Toast";
window.Toast = Toast;

//Importing Partial Components
import TopBar from "./components/partials/TopBar.vue";
import Sidebar from "./components/partials/Sidebar.vue";
// import App from "./App.vue"
// Moment date Format
import moment from "moment";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { routes } from "./router/index.js";
const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "",
  linkExactActiveClass: "router-link-active",
});

import { store } from "./store/index";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

function getAccessToken() {
  return localStorage.getItem("access_token");
}
function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}
//Authenticate Token
const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
// Add interceptors for validate authorization
// axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error.response.status === 401) {
//         store.dispatch('destroyUnAuthorizedToken')
//         return router.push({ name: "login" })
//       }
//       return Promise.reject(error)
//     }
//   );

// Add interceptors for validate authorization
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401) {
//       const form = { refresh_token: localStorage.getItem("refresh_token") };
//       axios.defaults.headers.common["Authorization"] =
//         "Bearer " + localStorage.getItem("access_token");

//       // return axios.post("/admin/refresh-token", form).then((res) => {
//       //   console.log(res.data);
//       //   if (res.status === 200) {
//       //     localStorage.setItem("access_token", res.data.token);
//       //     localStorage.setItem("refresh_token", res.data.refresh_token);

//       //     axios.defaults.headers.common["Authorization"] =
//       //       "Bearer " + res.data.token;
//       //     originalRequest.headers["Authorization"] = "Bearer " + res.data.token;
//       //     return axios(originalRequest);
//       //   } else {
//       //     store.dispatch("destroyUnAuthorizedToken");
//       //     return router.push({ name: "Login" });
//       //   }
//       // });

//       // store.dispatch("destroyUnAuthorizedToken");
//       // return router.push({ name: "login" });
//     }
//     return Promise.reject(error);
//   }
// );

const refreshTokenLogic = (failedRequest) =>
  axios
    .post("/admin/refresh-token", { refresh_token: getRefreshToken() })
    .then((res) => {
      // console.log(res);
      localStorage.setItem("access_token", res.data.token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      const bearer = res?.data?.token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + bearer;

      // originalRequest.headers["Authorization"] = "Bearer " + bearer;
      failedRequest.response.config.headers.Authorization = `Bearer ${bearer}`;
      return Promise.resolve();
    })
    .catch((err) => {
      store.dispatch("destroyUnAuthorizedToken");
      return router.push({ name: "Login" });
      // return Promise.reject(err);
    })
    .finally((err) => Promise.resolve(err));

createAuthRefreshInterceptor(axios, refreshTokenLogic, {
  shouldRefresh: (error) => getAccessToken() && error.response.status === 401,
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).utc().format("MMMM D, YYYY");
  }
});

const app = new Vue({
  el: "#app",
  router,
  vuetify,
  store: store,
  components: {
    TopBar,
    Sidebar,
  },
});
