// import { createRouter, createWebHistory } from 'vue-router'

import Login from "../components/auth/Login.vue";
import ForgotPassword from "../components/auth/ForgotPassword.vue";
import ChangePassword from "../components/auth/ChangePassword.vue";
import Logout from "../components/auth/Logout.vue";
import Dashboard from "../components/dashboard/Dashboard.vue";
import Onboardings from "../components/cms/onboarding/index.vue";
import Users from "../components/users/index.vue";
import Admins from "../components/admins/index.vue";
import Profile from "../components/admins/profile.vue";
import Breeds from "../components/pets/breeds/index.vue";
import Pets from "../components/pets/index.vue";
import PetsAdd from "../components/pets/create.vue";
import PetsDetail from "../components/pets/petsDetails.vue";
import CreateVaccineRecord from "../components/vaccine/createVaccineRecord.vue";
import EditVaccineRecord from "../components/vaccine/edit.vue";
import Vaccine from "../components/vaccine/index.vue";
import NewSection from "../components/newsection/index.vue";
import CheckInPlace from "../components/checkInPlace/index.vue";
//Nutrition
import Nutrition from "../components/nutrition/index.vue";
import NutritionCreate from "../components/nutrition/create.vue";
import NutritionEdit from "../components/nutrition/edit.vue";
import NutritionCategory from "../components/nutrition/category.vue";
//Push Notification
import PushNotification from "../components/pushNotification/index.vue";
//CMS Pages
import PrivacyPolicy from "../components/cms/pages/privacyPolicy.vue";
import TermsCondition from "../components/cms/pages/termsAndConditions.vue";
//merchant
import Merchant from "../components/merchant/index.vue";
import MerchantTag from "../components/merchant/tags/index.vue";
// import MerchantLogin from '../components/merchant/auth/login.vue';
//First Aid
import FirstAid from "../components/firstaid/index.vue";
import CreateFirstAid from "../components/firstaid/create.vue";
import ViewFirstAid from "../components/firstaid/view.vue";
import EditFirstAid from "../components/firstaid/edit.vue";
import Settings from "../components/settings/index.vue";
import WhatsNew from "../components/whatsNew/index.vue";

//import 404 component
import PageNotFound from "../components/cms/pages/PageNotFound404.vue";

export const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { requiresVisitor: true },
  },
  {
    path: "/reset-password/:token",
    name: "change-password",
    component: ChangePassword,
    meta: { requiresVisitor: true },
    props: { asset_url: "/" },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/onboardings",
    name: "onboardings",
    component: Onboardings,
    props: {
      watermark: {
        imgsrc: "/images/onboard.png",
      },
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/nutrition/categories",
    name: "NutritionCategory",
    component: NutritionCategory,
    meta: { requiresAuth: true },
  },
  {
    path: "/nutrition",
    name: "Nutrition",
    component: Nutrition,
    meta: { requiresAuth: true },
    props: {
      csv_path: "/excel-imports/nutritions.xlsx",
    },
  },
  {
    path: "/nutrition/create",
    name: "NutritionCreate",
    component: NutritionCreate,
    meta: { requiresAuth: true },
  },
  {
    path: "/nutrition/edit/:id",
    name: "NutritionEdit",
    component: NutritionEdit,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: "/admins",
    name: "admins",
    component: Admins,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/breeds",
    name: "breeds",
    component: Breeds,
    meta: { requiresAuth: true },
  },
  {
    path: "/pets",
    name: "pets",
    component: Pets,
    meta: { requiresAuth: true },
  },
  {
    path: "/pets/create",
    name: "petsadd",
    component: PetsAdd,
    meta: { requiresAuth: true },
  },
  {
    path: "/pet/:id",
    name: "pets-details",
    component: PetsDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/pet/create-vaccine-record/:id",
    name: "create-vaccine-record",
    component: CreateVaccineRecord,
    meta: { requiresAuth: true },
  },
  {
    path: "/pet/edit-vaccine-record/:id/:vaccine_id",
    name: "EditVaccineRecord",
    component: EditVaccineRecord,
    meta: { requiresAuth: true },
  },
  {
    path: "/vaccine",
    name: "vaccine",
    component: Vaccine,
    meta: { requiresAuth: true },
  },
  {
    path: "/new-section",
    name: "newsection",
    component: NewSection,
    meta: { requiresAuth: true },
  },
  {
    path: "/backend/merchants",
    name: "merchant",
    component: Merchant,
    meta: { requiresAuth: true },
  },
  {
    path: "/backend/merchant/tags",
    name: "merchantTags",
    component: MerchantTag,
    meta: { requiresAuth: true },
  },
  {
    path: "/check-in-place",
    name: "checkinplace",
    component: CheckInPlace,
    meta: { requiresAuth: true },
  },
  //First Aids
  {
    path: "/first-aids",
    name: "FirstAid",
    component: FirstAid,
    meta: { requiresAuth: true },
    props: {
      csv_path: "/excel-imports/first-aids.xlsx",
    },
  },
  {
    path: "/first-aid/create",
    name: "CreateFirstAid",
    component: CreateFirstAid,
    meta: { requiresAuth: true },
  },
  {
    path: "/first-aid/:id",
    name: "ViewFirstAid",
    component: ViewFirstAid,
    meta: { requiresAuth: true },
  },
  {
    path: "/first-aid/edit/:id",
    name: "EditFirstAid",
    component: EditFirstAid,
    meta: { requiresAuth: true },
  },
  //Push Notification
  {
    path: "/push-notificaiton",
    name: "push-notificaiton",
    component: PushNotification,
    meta: { requiresAuth: true },
  },

  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: { requiresAuth: false },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsCondition,
    meta: { requiresAuth: false },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { requiresAuth: false },
  },
  {
    path: "/whats-new",
    name: "WhatsNew",
    component: WhatsNew,
    meta: { requiresAuth: false },
  },
  // merchant authentication
  // {
  //     path: '/register/merchant', name: 'MerchantLogin', component: MerchantLogin, meta: { requiresAuth: false, }, props: {
  //         asset_url: "/",
  //     },
  // },
  //this is last route for 404 page
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { requiresAuth: true },
  },
];
