<template>
  <div>
    <v-data-table
      :headers="petHeaders"
      :items="pets"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="toolbar-left">
            <v-card-title>
              <v-toolbar-title class="panel-with">
                <h3 class="panel-title">Registered pets</h3></v-toolbar-title
              >
            </v-card-title>
          </div>
          <div class="toolbar-right">
            <div class="filter-select">
              <FilterByDateDashboard
                :newFilterOption="newFilterOption"
                @changedFilterOption="getChangedFilterOption"
              ></FilterByDateDashboard>
            </div>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.sn="{ index }">
        {{ index + 1 }}
      </template>
    </v-data-table>
    <!-- <pagination
      :totalItems="pagination.totalItems + ' Pets'"
      :totalPages="pagination.totalPages"
      :perPage="pagination.perPage"
      :currentPage="pagination.currentPage"
      @pagechanged="getData"
    ></pagination> -->
  </div>
</template>

<script>
import pagination from "../subComponents/pagination.vue";
import FilterByDateDashboard from "./filterByDateDashboard.vue";
export default {
  name: "RegisteredPetsComponent",
  components: { pagination, FilterByDateDashboard },
  data() {
    return {
      loading: false,
      newFilterOption: null,
      pets: [],
      filterBy: null,
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      petHeaders: [
        {
          text: "S.N.",
          align: "start",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          align: "start",
        },
        {
          text: "Breed",
          value: "name",
          sortable: true,
          align: "start",
        },

        {
          text: "Register Date",
          value: "reg_date",
          sortable: true,
          align: "start",
        },
        {
          text: "Guardian name",
          value: "guardian_name",
          sortable: true,
          align: "start",
        },
        {
          text: "Blood Donor",
          value: "donor",
          sortable: true,
          align: "start",
        },
        {
          text: "Blood type",
          value: "blood_type",
          sortable: true,
          align: "start",
        },
      ],
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getData(this.pagination.currentPage);
  },
  methods: {
    getData(page) {
      this.loading = true;
      axios
        .get(`/admin/registered-pets?date=` + this.filterBy)
        .then(({ data }) => {
          //   console.log("pets", data);
          this.pets = data.data;
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    getChangedFilterOption(option) {
      this.filterBy = option;
      this.getData(this.pagination.currentPage);
    },
  },
};
</script>

<style></style>
