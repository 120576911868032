<template>
    <nav
        class="main-navbar navbar navbar-expand-lg"
        v-if="!$route.meta.requiresVisitor"
    >
        <div class="container">
            <!-- <button
        type="button"
        class="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->
            <div
                class="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
            >
                <router-link to="/" class="navbar-brand">
                    <img :src="image_path + '/logo.png'" alt="Site branding" />
                </router-link>

                <div class="navbar-nav user-area">
                    <a
                        href="#"
                        class="user-toggle dropdown-toggle"
                        data-bs-toggle="dropdown"
                    >
                        <!-- <img
              :src="
                $store.state.user_image == 'null'
                  ? image_path + '/user-watermark.png'
                  : $store.state.user_image
              "
              alt="Avatar Logo"
              width="40"
              height="40"
              class="rounded-pill"
            /> -->
                        <!-- <img
              :src="
                userImage == 'null' ? '/images/user-deafult.png' : userImage
              "
              alt="Avatar Logo"
              width="40"
              height="40"
              class="rounded-pill"
            /> -->
                        <template v-if="userImage == 'null'">
                            <img
                                src="/images/user-deafult.png"
                                alt="Avatar Logo"
                                width="40"
                                height="40"
                                class="rounded-pill"
                            />
                        </template>
                        <template v-else>
                            <div class="user_character">
                                <span>{{
                                    $store.state.user_name.charAt(0)
                                }}</span>
                            </div>
                        </template>
                        <div class="text-wrap">
                            <span class="user-name">{{
                                $store.state.user_name
                            }}</span>
                            <span
                                class="user-role"
                                v-if="$store.state.is_admin == 1"
                                >Admin</span
                            >
                            <span class="user-role" v-else>User</span>
                        </div>
                    </a>
                    <div class="dropdown-menu">
                        <router-link
                            :to="{ name: 'profile' }"
                            class="dropdown-item"
                            >Profile</router-link
                        >
                        <router-link to="/settings" class="dropdown-item">
                            <span> Settings </span>
                        </router-link>
                        <router-link
                            :to="{ name: 'logout' }"
                            class="dropdown-item"
                            dusk="login-out"
                            >Logout</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    name: "TopBar",
    props: ["image_path", "index_route"],
    data() {
        return {
            userImage: this.$store.state.user_image,
        };
    },
    mounted() {
        console.log(this.$store.state.user_image);
        //    console.log();
        //   console.log("tests");
        this.userImage = this.$store.state.user_name.charAt(0);
    },
};
</script>

<style></style>
