<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-nutrition-create">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" to="/nutrition">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">Edit nutrition item</h3>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <form @submit.prevent="updateNutrition">
              <v-app>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="vaccine">Item-EN</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter item in EN"
                        v-model="form.item_en"
                        @click="errors.item_en = null"
                      />
                      <small class="text-danger" v-if="errors.item_en"
                        >{{ errors.item_en[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="vaccine">Item-ZH</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.item_zh"
                        placeholder="Enter item in ZH"
                        @click="errors.item_zh = null"
                      />
                      <small class="text-danger" v-if="errors.item_zh"
                        >{{ errors.item_zh[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Category</label>
                      <v-select
                        v-model="form.category"
                        :items="categoryOptions"
                        item-text="name"
                        item-value="id"
                        outlined
                        placeholder="Select Category"
                        attach
                        @click="errors.category = null"
                      >
                        <template v-slot:append-item>
                          <v-list-item class="add-category-text">
                            <v-list-item-content>
                              <span
                                class="btn-text"
                                @click="AddNewCategory"
                                style="cursor: pointer; display: block"
                              >
                                Add new
                              </span>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                      <small class="text-danger" v-if="errors.category"
                        >{{ errors.category[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Pet type</label>
                      <v-select
                        v-model="form.pet_type"
                        :items="petTypeOption"
                        item-text="text"
                        item-value="val"
                        outlined
                        attach
                        @click="errors.pet_type = null"
                      >
                      </v-select>
                      <small class="text-danger" v-if="errors.pet_type"
                        >{{ errors.pet_type[0] }}
                      </small>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Edible</label>
                      <v-select
                        v-model="form.edible"
                        :items="edibleOption"
                        outlined
                        attach
                        @click="errors.edible = null"
                      >
                      </v-select>
                      <small class="text-danger" v-if="errors.edible"
                        >{{ errors.edible[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="radio-group-wrap">
                        <label for="Processed">Processed:</label>
                        <v-radio-group v-model="form.processed" column>
                          <v-radio
                            label="Yes"
                            color="#E77386"
                            :value="is_processed[1]"
                            @click="errors.processed = null"
                          ></v-radio>

                          <v-radio
                            label="No"
                            color="#E77386"
                            :value="is_processed[0]"
                            @click="errors.processed = null"
                          ></v-radio>
                        </v-radio-group>
                        <small class="text-danger" v-if="errors.processed"
                          >{{ errors.processed[0] }}
                        </small>
                      </div>
                      <div class="radio-group-wrap" v-if="form.pet_type == 0">
                        <label for="Processed">Dcm:</label>
                        <v-radio-group v-model="form.dcm" column>
                          <v-radio
                            label="Yes"
                            color="#E77386"
                            :value="is_dcm[1]"
                            @click="errors.dcm = null"
                          ></v-radio>

                          <v-radio
                            label="No"
                            color="#E77386"
                            :value="is_dcm[0]"
                            @click="errors.dcm = null"
                          ></v-radio>
                        </v-radio-group>
                        <small class="text-danger" v-if="errors.dcm"
                          >{{ errors.dcm[0] }}
                        </small>
                      </div>
                      <div class="radio-group-wrap">
                        <label for="Processed">Highfat:</label>
                        <v-radio-group v-model="form.highfat" column>
                          <v-radio
                            label="Yes"
                            color="#E77386"
                            :value="is_highfat[1]"
                            @click="errors.highfat = null"
                          ></v-radio>
                          <v-radio
                            label="No"
                            color="#E77386"
                            :value="is_highfat[0]"
                            @click="errors.highfat = null"
                          ></v-radio>
                        </v-radio-group>
                        <small class="text-danger" v-if="errors.highfat"
                          >{{ errors.highfat[0] }}
                        </small>
                      </div>
                      <div class="radio-group-wrap">
                        <label for="Processed">Carb:</label>
                        <v-radio-group v-model="form.carb" column>
                          <v-radio
                            label="Yes"
                            color="#E77386"
                            :value="is_carb[1]"
                            @click="errors.carb = null"
                          ></v-radio>
                          <v-radio
                            label="No"
                            color="#E77386"
                            :value="is_carb[0]"
                            @click="errors.carb = null"
                          ></v-radio>
                        </v-radio-group>
                        <small class="text-danger" v-if="errors.carb"
                          >{{ errors.carb[0] }}
                        </small>
                      </div>
                      <div class="radio-group-wrap">
                        <label for="Processed">Canned:</label>
                        <v-radio-group v-model="form.canned" column>
                          <v-radio
                            label="Yes"
                            color="#E77386"
                            :value="is_canned[1]"
                            @click="errors.canned = null"
                          ></v-radio>
                          <v-radio
                            label="No"
                            color="#E77386"
                            :value="is_canned[0]"
                            @click="errors.canned = null"
                          ></v-radio>
                        </v-radio-group>
                        <small class="text-danger" v-if="errors.canned"
                          >{{ errors.canned[0] }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Content-En</label>
                      <v-textarea
                        name=""
                        value=""
                        @keyup="lengthPad('form.content_en')"
                        :maxlength="max"
                        placeholder="Enter content-EN"
                        v-model="form.content_en"
                        @click="errors.content_en = null"
                      ></v-textarea>
                      <small class="char-limit">
                        {{ noteLength_en }}/{{ max }}
                      </small>
                      <small class="text-danger" v-if="errors.content_en"
                        >{{ errors.content_en[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Content-Zh</label>
                      <v-textarea
                        name=""
                        value=""
                        @keyup="lengthPad('form.content_zh')"
                        :maxlength="max"
                        placeholder="Enter content-ZH "
                        v-model="form.content_zh"
                        @click="errors.content_zh = null"
                      ></v-textarea>
                      <small class="char-limit">
                        {{ noteLength_zh }}/{{ max }}
                      </small>
                      <small class="text-danger" v-if="errors.content_zh"
                        >{{ errors.content_zh[0] }}
                      </small>
                    </div>
                  </div>
                </div>
                <v-dialog v-model="addCategory" max-width="600px">
                  <v-card class="add-category">
                    <v-card-title>
                      <span class="modal-title">New category</span>
                    </v-card-title>
                    <form @submit.prevent="addNutritionCategory">
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="vaccine">Category name(En)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="categoryForm.name_en"
                                @click="categoryErrors.name_en = null"
                              />
                              <small
                                class="text-danger"
                                v-if="categoryErrors.name_en"
                              >
                                {{ categoryErrors.name_en[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="vaccine">Category name(Zh)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="categoryForm.name_zh"
                                @click="categoryErrors.name_zh = null"
                              />
                              <small
                                class="text-danger"
                                v-if="categoryErrors.name_zh"
                              >
                                {{ categoryErrors.name_zh[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="vaccine">Image</label>
                              <ImageDragDrap
                                removeIcon="false"
                                      max="10"
                                @imageDetails="checkboxValue"
                              ></ImageDragDrap>
                              <small
                                class="text-danger"
                                v-if="categoryErrors.image"
                              >
                                {{ categoryErrors.image[0] }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <div class="actions">
                          <button
                            type="submit"
                            class="btn btn-primary btn-save"
                            :disabled="loadingCategory"
                          >
                            Save
                            <span class="loading-dots" v-if="loadingCategory">
                            </span>
                          </button>
                          <button
                            type="reset"
                            class="btn btn-secondary btn-cancel"
                            @click="addCategory = false"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </v-card>
                </v-dialog>
              </v-app>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                Save <span class="loading-dots" v-if="loading"></span>
              </button>
            </form>
          </div>
          <div class="panel-footer">
            <div class="actions"></div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>



<script>
import BackArrow from "../icons/BackArrow.vue";
import ImageDragDrap from "../subComponents/imageDragDrop.vue";
export default {
  name: "NutritionCreate",
  components: {
    BackArrow,
    ImageDragDrap,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      loadingCategory: false,
      categoryOptions: [],
      petTypeOption: [
        { text: "Dog", val: 0 },
        { text: "Cat", val: 1 },
      ],
      edibleOption: ["Toxic", "Okay", "Good", "Great"],
      addCategory: false,
      is_processed: [0, 1],
      is_highfat: [0, 1],
      is_dcm: [0, 1],
      is_carb: [0, 1],
      is_canned: [0, 1],
      form: {
        pet_type: null,
        item_en: "",
        item_zh: "",
        category: "",
        edible: "Toxic",
        processed: 1,
        dcm: 1,
        carb: 1,
        highfat: 1,
        canned: 1,
        content_en: "",
        content_zh: "",
      },
      categoryForm: {
        name_en: "",
        name_zh: "",
        image: null,
      },
      errors: {},
      categoryErrors: {},
      max: "1500",
      noteLength_en: "0",
      noteLength_zh: "0",
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getNutritionCategories();
    this.getNutrition();
  },
  methods: {
    AddNewCategory() {
      this.addCategory = true;
    },
    getNutritionCategories() {
      axios
        .get(`/admin/nutrition/category`)
        .then(({ data }) => {
          this.categoryOptions = data;
          // console.log(this.categoryOptions);
        })
        .catch((error) => console.log(error));
    },
    addNutritionCategory() {
      this.loadingCategory = true;
      axios
        .post(`/admin/nutrition/category/create`, this.categoryForm)
        .then(({ data }) => {
          this.addCategory = false;
          this.loadingCategory = false;
          Toast.success();
          this.getNutritionCategories();
          this.categoryForm.name_en = null;
          this.categoryForm.name_zh = null;
          this.categoryForm.image = null;
          this.form.category = this.categoryOptions.length + 1;
        })
        .catch((error) => {
          this.categoryErrors = error.response.data.errors;
          // console.log(this.categoryErrors);
          this.loadingCategory = false;
        });
    },
    getNutrition() {
      axios
        .get(`/admin/nutrition/edit/${this.id}`)
        .then(({ data }) => {
            // console.log(data);

          let nutrition_en = {};
          data.all_translations.map(function (translation) {
            if (translation.lang_code == "en") {
              nutrition_en = translation;
            }
          });
          let nutrition_zh = {};
          data.all_translations.map(function (translation) {
            if (translation.lang_code == "zh") {
              nutrition_zh = translation;
            }
          });
          this.form.pet_type = data.pet_type;
          this.form.item_en = data.item;
          this.form.item_zh = nutrition_zh.item;
          this.form.category = data.nutrition_category_id;
          this.form.edible = data.edible;
          this.form.processed = data.is_processed;
          this.form.dcm = data.is_dcm;
          this.form.carb = data.is_carb;
          this.form.highfat = data.is_highfat;
          this.form.canned = data.is_canned;
          this.form.content_en = nutrition_en.content;
          this.form.content_zh = nutrition_zh.content;
          this.noteLength_en = nutrition_en.content != null ? nutrition_en.content.length : 0;
          this.noteLength_zh = nutrition_zh.content != null ? nutrition_zh.content.length : 0;
        })
        .catch((error) => console.log(error));
    },
    updateNutrition() {
      this.loading = true;
      axios
        .put(`/admin/nutrition/update/${this.id}`, this.form)
        .then(({ data }) => {
          Toast.success();
          this.$router.push({ name: "Nutrition" });
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors;
          // console.log(this.errors);
        });
    },
    checkboxValue(data) {
      // console.log('hello'+data);
      this.categoryErrors.image = null;
      this.categoryForm.image = data;
    },
    lengthPad(num) {
      if (num == "form.content_en") {
        let lengthDog = this.form.content_en.length;
        this.noteLength_en = ("" + lengthDog).slice(-3);
      } else {
        let lengthDog = this.form.content_zh.length;
        this.noteLength_zh = ("" + lengthDog).slice(-3);
      }
    },
  },
};
</script>

<style>
</style>
