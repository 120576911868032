<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M12.16 1.08125L4.54 8.70124C4.24896 8.99071 4.01823 9.33503 3.86117 9.71427C3.70411 10.0935 3.62384 10.5001 3.625 10.9106V11.75C3.625 11.9158 3.69085 12.0747 3.80806 12.1919C3.92527 12.3091 4.08424 12.375 4.25 12.375H5.08937C5.49985 12.3762 5.90648 12.2959 6.28572 12.1388C6.66496 11.9818 7.00928 11.751 7.29875 11.46L14.9187 3.84C15.284 3.47385 15.4891 2.9778 15.4891 2.46062C15.4891 1.94345 15.284 1.44739 14.9187 1.08125C14.5473 0.726183 14.0532 0.528031 13.5394 0.528031C13.0255 0.528031 12.5314 0.726183 12.16 1.08125ZM14.035 2.95625L6.415 10.5762C6.06257 10.9265 5.58625 11.1237 5.08937 11.125H4.875V10.9106C4.8763 10.4137 5.07348 9.93741 5.42375 9.58499L13.0437 1.965C13.1772 1.83747 13.3548 1.76631 13.5394 1.76631C13.724 1.76631 13.9015 1.83747 14.035 1.965C14.1662 2.09657 14.2399 2.27481 14.2399 2.46062C14.2399 2.64644 14.1662 2.82468 14.035 2.95625Z" fill="#565656"/>
<path d="M14.875 6.11187C14.7092 6.11187 14.5503 6.17772 14.433 6.29493C14.3158 6.41214 14.25 6.57111 14.25 6.73687V9.87499H11.75C11.2527 9.87499 10.7758 10.0725 10.4242 10.4242C10.0725 10.7758 9.87499 11.2527 9.87499 11.75V14.25H3.625C3.12772 14.25 2.6508 14.0524 2.29917 13.7008C1.94754 13.3492 1.75 12.8723 1.75 12.375V3.625C1.75 3.12772 1.94754 2.6508 2.29917 2.29917C2.6508 1.94754 3.12772 1.75 3.625 1.75H9.27624C9.442 1.75 9.60097 1.68415 9.71819 1.56694C9.8354 1.44973 9.90124 1.29076 9.90124 1.125C9.90124 0.959239 9.8354 0.800268 9.71819 0.683058C9.60097 0.565848 9.442 0.5 9.27624 0.5H3.625C2.7965 0.500992 2.00222 0.830551 1.41639 1.41639C0.830551 2.00222 0.500992 2.7965 0.5 3.625V12.375C0.500992 13.2035 0.830551 13.9978 1.41639 14.5836C2.00222 15.1694 2.7965 15.499 3.625 15.5H10.7144C11.1249 15.5012 11.5317 15.4209 11.911 15.2638C12.2904 15.1068 12.6348 14.876 12.9244 14.585L14.5844 12.9237C14.8755 12.6343 15.1063 12.29 15.2634 11.9107C15.4206 11.5315 15.501 11.1249 15.5 10.7144V6.73687C15.5 6.57111 15.4341 6.41214 15.3169 6.29493C15.1997 6.17772 15.0408 6.11187 14.875 6.11187ZM12.0406 13.7012C11.7894 13.9519 11.4717 14.1254 11.125 14.2012V11.75C11.125 11.5842 11.1908 11.4253 11.308 11.308C11.4253 11.1908 11.5842 11.125 11.75 11.125H14.2031C14.1258 11.4709 13.9525 11.7881 13.7031 12.04L12.0406 13.7012Z" fill="#565656"/>
</svg>
</template>

<script>
export default {
  name: "EditIcon",
};
</script>

<style>
</style>
