<template>
    <section class="section mt-5">
        <div class="panel">
            <div class="panel-header">
                <div class="title-wrapper">
                    <h2 class="panel-title">Terms and conditions</h2>
                </div>
            </div>
            <div class="panel-body">
                <form @submit.prevent="submit">
                    <v-app>
                        <v-row>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="label">Title (En)</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="form.title_en"
                                        @click="errors.title_en = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.title_en"
                                        >{{ errors.title_en[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="label">Title (Zh)</label>
                                    <input
                                        v-model="form.title_zh"
                                        type="text"
                                        class="form-control"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.title_zh"
                                    >
                                        {{ errors.title_zh[0] }}
                                    </small>
                                </div>
                            </div>

                            <v-col cols="12" md="6">
                                <div class="form-group">
                                    <label class="label">Content (En)</label>

                                    <VueEditor
                                        v-model="form.content_en"
                                        :editorToolbar="customToolbar"
                                        @click="errors.content_en = null"
                                        @input="errors.content_en = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.content_en"
                                    >
                                        {{ errors.content_en[0] }}
                                    </small>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <div class="form-group">
                                    <label class="label">Content (Zh)</label>
                                    <VueEditor
                                        v-model="form.content_zh"
                                        :editorToolbar="customToolbar"
                                        @click="errors.content_zh = null"
                                        @input="errors.content_zh = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.content_zh"
                                    >
                                        {{ errors.content_zh[0] }}
                                    </small>
                                </div>
                            </v-col>

                            <div class="col col-12">
                                <button
                                    class="btn btn-primary btn-save"
                                    :disabled="loading"
                                >
                                    Save
                                    <span class="loading-dots" v-if="loading">
                                    </span>
                                </button>
                            </div>
                        </v-row>
                    </v-app>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
    name: "terms-and-conditions-component",
    components: { VueEditor },
    data() {
        return {
            loading: false,
            slug_id: 2,
            form: {
                id: "",
                title_en: "",
                title_zh: "",
                content_en: "",
                content_zh: "",
            },
            customToolbar: [
                [
                    {
                        header: [false, 1, 2, 3, 4, 5, 6],
                    },
                ],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    {
                        align: "",
                    },
                    {
                        align: "center",
                    },
                    {
                        align: "right",
                    },
                    {
                        align: "justify",
                    },
                ],
                // ["blockquote",
                // "code-block"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                    // {
                    //     list: "check",
                    // },
                ],
                // [
                //     {
                //         indent: "-1",
                //     },
                //     {
                //         indent: "+1",
                //     },
                // ], // outdent/indent
                [
                    {
                        color: [],
                    },
                    {
                        background: [],
                    },
                ], // dropdown with defaults from theme
                [
                    "link",
                    //  "image",
                    //  "video"
                ],
                // ["clean"], // remove formatting button
            ],
            errors: {},
        };
    },
    created() {
        const token = localStorage.getItem("access_token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(page) {
            this.loading = true;
            axios
                .get(`/admin/settings/cms/${this.slug_id}`)
                .then(({ data }) => {
                    if (data != "") {
                        this.form.id = data.id;
                        this.form.title_en = data.all_translations[0].title;
                        this.form.title_zh = data.all_translations[1].title;
                        this.form.content_en = data.all_translations[0].content;
                        this.form.content_zh = data.all_translations[1].content;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            if (this.form.id == "") {
                this.form.id = this.slug_id;
                axios
                    .post(`/admin/settings/cms/create`, this.form)
                    .then(({ data }) => {
                        this.loading = false;
                        Toast.success();
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        this.loading = false;
                    });
            } else {
                axios
                    .post(`/admin/settings/cms/${this.form.id}`, this.form)
                    .then(({ data }) => {
                        this.loading = false;
                        Toast.success();
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.panel-body {
    max-width: 1060px;
}
.form-group {
    margin-bottom: 20px;
    .label {
        margin-bottom: 8px;
    }
}
.btn-save {
    &:disabled {
        background-color: var(--primary-color);
    }
    .loading-dots {
        position: relative;
        top: 1px;
        left: unset;
    }
}
</style>
