<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16 9V6L20 10L16 14V11H11V16H14L10 20L6 16H9V11H4V14L0 10L4 6V9H9V4H6L10 0L14 4H11V9H16Z"
      fill="#7C7C7C"
    />
  </svg>
</template>
<script>
export default {
  name: "DragIcon",
};
</script>

<style>
</style>
