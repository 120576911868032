<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-dashboard">
        <div class="panel">
          <div class="panel-header">
            <h5 class="panel-title">Dashboard</h5>
            <div class="form-filter-wrap">
              <form @submit.prevent="filterDashboard">
                <label>Filter by:</label>
                <div class="actions">
                  <button
                    type="submit"
                    class="btn btn-border"
                    :class="activeFilter === 'weekly' ? 'active' : ''"
                    @click="() => (activeFilter = 'weekly')"
                  >
                    Weekly
                  </button>
                  <button
                    type="submit"
                    class="btn btn-border"
                    :class="activeFilter === 'byweekly' ? 'active' : ''"
                    @click="() => (activeFilter = 'byweekly')"
                  >
                    Byweekly
                  </button>
                  <button
                    type="submit"
                    class="btn btn-border"
                    :class="activeFilter === 'monthly' ? 'active' : ''"
                    @click="() => (activeFilter = 'monthly')"
                  >
                    Monthly
                  </button>
                  <button
                    type="submit"
                    class="btn btn-border"
                    :class="activeFilter === 'quarterly' ? 'active' : ''"
                    @click="() => (activeFilter = 'quarterly')"
                  >
                    Quarterly
                  </button>
                  <button
                    type="submit"
                    class="btn btn-border"
                    :class="activeFilter === 'yearly' ? 'active' : ''"
                    @click="() => (activeFilter = 'yearly')"
                  >
                    Yearly
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="panel-body">
            <div class="dashboard-widgets">
              <ul class="lists">
                <li class="list" v-for="(item, index) in widgets" :key="index">
                  <div class="list-wrap" :class="item.class">
                    <div class="list-header">
                      <span class="list-title">
                        {{ item.title }}
                      </span>
                      <span class="icon">
                        <!-- v-html="item.icon" -->
                        <template v-if="item.icon === 'user_icon'">
                          <UserIcon />
                        </template>
                        <template v-else-if="item.icon === 'pets_icon'">
                          <PetsIcon />
                        </template>
                        <template v-else-if="item.icon === 'check_in_icon'">
                          <CheckInIcon />
                        </template>
                        <template v-else>
                          <DonorIcon />
                        </template>
                      </span>
                    </div>
                    <div class="list-body">
                      <div class="list-content">
                        <div class="numbers">
                          {{ item.total_users }}
                          {{ item.total_pets }}
                        </div>
                        <div class="spot" v-html="item.check_in_spot"></div>
                        <span v-if="item.percentage != null">
                          Increased by
                          {{ item.percentage }}%
                        </span>
                        <span v-if="item.counter != null">
                          {{ item.counter }} Times
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <v-app class="">
              <v-row>
                <v-col md="6" class="mb-30">
                  <check-in-places-component />
                </v-col>
                <v-col md="6" class="mb-30">
                  <UsersComponent />
                </v-col>
              </v-row>
              <div class="bottom-dashboard">
                <div class="table_holder">
                  <RegisteredPetsComponent />
                </div>
                <div class="import-group">
                  <div class="dashboard-import-area breed-import-area">
                    <div class="card">
                      <form
                        @submit.prevent="ImportBreed"
                        method="POST"
                        enctype="multipart/form-data"
                      >
                        <div class="form-group">
                          <div class="form-row">
                            <div class="form-title-wrap">
                              <h3 class="form-title">Import Breed</h3>
                            </div>
                            <div class="field-wrap">
                              <span
                                class="file-name"
                                v-if="import_file_name_breed != null"
                                >{{ import_file_name_breed }}</span
                              >
                              <div
                                v-if="error.message"
                                class="invalid-feedback"
                              >
                                Invalid
                              </div>
                              <div v-if="!import_file_state_breed">
                                <a
                                  class="btn btn-primary"
                                  @click="$refs.import_file_breed.click()"
                                >
                                  Import
                                </a>

                                <input
                                  type="file"
                                  class="form-control"
                                  id="input-file-import"
                                  name="file_import_breed"
                                  ref="import_file_breed"
                                  accept=".xlsx, .xls"
                                  @change="onFileChangeBreed"
                                />
                              </div>

                              <div class="actions" v-else>
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  :disabled="importing_breed"
                                >
                                  Submit
                                  <span
                                    class="loading-dots"
                                    v-if="importing_breed"
                                  ></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="dashboard-import-area pet-import-area">
                    <div class="card">
                      <form
                        @submit.prevent="ImportPet"
                        method="POST"
                        enctype="multipart/form-data"
                      >
                        <div class="form-group">
                          <div class="form-row">
                            <div class="form-title-wrap">
                              <h3 class="form-title">Import Pet</h3>
                            </div>
                            <div class="field-wrap">
                              <span
                                class="file-name"
                                v-if="import_file_name_pet != null"
                                >{{ import_file_name_pet }}</span
                              >
                              <div
                                v-if="error.message"
                                class="invalid-feedback"
                              >
                                Invalid
                              </div>
                              <div v-if="!import_file_state_pet">
                                <a
                                  class="btn btn-primary"
                                  @click="$refs.import_file_pet.click()"
                                >
                                  Import
                                </a>

                                <input
                                  type="file"
                                  class="form-control"
                                  id="input-file-import"
                                  name="file_import_pet"
                                  ref="import_file_pet"
                                  accept=".xlsx, .xls"
                                  @change="onFileChangePet"
                                />
                              </div>

                              <div class="actions" v-else>
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  :disabled="importing_pets"
                                >
                                  Submit
                                  <span
                                    class="loading-dots"
                                    v-if="importing_pets"
                                  ></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </v-app>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import FilterByDateDashboard from "./filterByDateDashboard.vue";
import axios from "axios";
import CheckInPlacesComponent from "./checkInPlaces.component.vue";
import UsersComponent from "./users.component.vue";
import RegisteredPetsComponent from "./registeredPets.component.vue";
import UserIcon from "./icons/user.icon.vue";
import PetsIcon from "./icons/pets.icon.vue";
import CheckInIcon from "./icons/checkIn.icon.vue";
import DonorIcon from "./icons/donor.icon.vue";

export default {
  name: "Dashboard",
  components: {
    FilterByDateDashboard,
    CheckInPlacesComponent,
    UsersComponent,
    RegisteredPetsComponent,
    UserIcon,
    PetsIcon,
    CheckInIcon,
    DonorIcon,
  },
  data() {
    return {
      loading: false,
      error: {},
      import_file_breed: "",
      import_file_name_breed: "",
      import_file_state_breed: false,
      import_file_pet: "",
      import_file_name_pet: "",
      import_file_state_pet: false,
      importing_breed: false,
      importing_pets: false,
      activeFilter: "weekly",
      filterData: "weekly",
      widgets: [
        {
          class: "area-users",
          title: "Users",
          total_users: 0,
          percentage: "0",
          icon: "user_icon",
        },
        {
          class: "area-pets",
          title: "Pets",
          total_pets: 0,
          percentage: "0",
          icon: "pets_icon",
        },
        {
          class: "area-recentCheckIn",
          title: "Most checked in spot",
          counter: 0,
          check_in_spot: "",
          icon: "check_in_icon",
        },
        {
          class: "area-bloodDonor",
          title: "No. of blood donors",
          donor: 15,
          total_pets: 0,
          donors_percentage: "+60",
          pets_percentage: "+30",
          icon: "blood_donor_icon",
        },
      ],
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.filterDashboard();
  },
  methods: {
    getUsers(page) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    },
    getPets(page) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    },
    filterDashboard() {
      axios
        .get(`/admin/info`)
        .then(({ data }) => {
          if (this.activeFilter == "weekly") {
            this.widgets[0].total_users = data.data.weekly.user;
            this.widgets[0].percentage = data.data.weekly.user_increased;
            this.widgets[3].total_pets = data.data.weekly.donor;
            this.widgets[1].total_pets = data.data.weekly.pet;
            this.widgets[1].percentage = data.data.weekly.pet_increased;
            this.widgets[2].check_in_spot = data.data.weekly.checkin;
            this.widgets[2].counter = data.data.weekly.total;
          } else if (this.activeFilter == "byweekly") {
            this.widgets[0].total_users = data.data.byweekly.user;
            this.widgets[0].percentage = data.data.byweekly.user_increased;
            this.widgets[3].total_pets = data.data.byweekly.donor;
            this.widgets[1].total_pets = data.data.byweekly.pet;
            this.widgets[1].percentage = data.data.byweekly.pet_increased;
            this.widgets[2].check_in_spot = data.data.byweekly.checkin;
            this.widgets[2].counter = data.data.byweekly.total;
          } else if (this.activeFilter == "monthly") {
            this.widgets[0].total_users = data.data.monthly.user;
            this.widgets[0].percentage = data.data.monthly.user_increased;
            this.widgets[3].total_pets = data.data.monthly.donor;
            this.widgets[1].total_pets = data.data.monthly.pet;
            this.widgets[1].percentage = data.data.monthly.pet_increased;
            this.widgets[2].check_in_spot = data.data.monthly.checkin;
            this.widgets[2].counter = data.data.monthly.total;
          } else if (this.activeFilter == "quarterly") {
            this.widgets[0].total_users = data.data.quarterly.user;
            this.widgets[0].percentage = data.data.quarterly.user_increased;
            this.widgets[3].total_pets = data.data.quarterly.donor;
            this.widgets[1].total_pets = data.data.quarterly.pet;
            this.widgets[1].percentage = data.data.quarterly.pet_increased;
            this.widgets[2].check_in_spot = data.data.quarterly.checkin;
            this.widgets[2].counter = data.data.quarterly.total;
          } else {
            this.widgets[0].total_users = data.data.yearly.user;
            this.widgets[0].percentage = data.data.yearly.user_increased;
            this.widgets[3].total_pets = data.data.yearly.donor;
            this.widgets[1].total_pets = data.data.yearly.pet;
            this.widgets[1].percentage = data.data.yearly.pet_increased;
            this.widgets[2].check_in_spot = data.data.yearly.checkin;
            this.widgets[2].counter = data.data.yearly.total;
          }
        })
        .catch((error) => console.log(error));
    },
    ImportBreed() {
      this.importing_breed = true;
      this.uploading = true;
      let formData = new FormData();
      formData.append("import_file", this.import_file_breed);
      axios
        .post("/admin/breeds-import", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.importing_breed = false;
          this.import_file_breed = "";
          this.import_file_name_breed = "";
          this.import_file_state_breed = false;
          if (response.status === 200) {
            Toast.success();
            this.$router.push({ name: "breeds" });
          }
        })
        .catch((error) => {
          this.importing_breed = false;
          this.uploading = false;
          this.error = error.response.data;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Invalid file!",
          });
          this.import_file_state_breed = false;
        });
    },
    ImportPet() {
      this.importing_pets = true;
      this.uploading = true;
      let formData = new FormData();
      formData.append("import_file", this.import_file_pet);
      axios
        .post("/admin/pets-import", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.importing_pets = false;
          this.import_file_pet = "";
          this.import_file_name_pet = "";
          this.import_file_state_pet = false;
          if (response.status === 200) {
            Toast.success();
            // this.$router.push({ name: "pets" });
          }
        })
        .catch((error) => {
          // code here when an upload is not valid
          this.importing_pets = false;
          this.uploading = false;
          this.error = error.response.data;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Invalid file!",
          });
          this.import_file_state_pet = false;
          // Toast.error();
        });
    },
    onFileChangeBreed(e) {
      this.import_file_breed = e.target.files[0];
      this.import_file_state_breed = true;
      this.import_file_name_breed = this.import_file_breed.name;
    },
    onFileChangePet(e) {
      this.import_file_pet = e.target.files[0];
      this.import_file_state_pet = true;
      this.import_file_name_pet = this.import_file_pet.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.panel {
  .panel-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

}
.panel-header > .panel-title {
  font-weight: 400;
  font-size: 20px;
  font-family: var(--body-font);
}
.v-data-table {
  .panel-title {
    font-weight: 500;
    font-size: 18px;
  }
}
</style>
