<template>
    <main role="main" class="bd-content">
        <div class="main-content">
            <div class="section section-setting">
                <div class="panel">
                    <div class="panel-body">
                        <v-app class="mt-12 ml-12">
                            <div class="title-wrap">
                                <h3 class="panel-title">Settings</h3>
                            </div>
                            <div class="tab-wrap">
                                <template>
                                    <v-tabs v-model="tab">
                                        <v-tabs-slider
                                            color="#e77386"
                                        ></v-tabs-slider>
                                        <v-tab
                                            v-for="item in items"
                                            :key="item"
                                        >
                                            {{ item }}
                                        </v-tab>
                                    </v-tabs>
                                </template>
                            </div>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <GeneralSettingComponent />
                                </v-tab-item>
                                <v-tab-item>
                                    <FaqComponent />
                                </v-tab-item>
                                <v-tab-item>
                                    <AboutComponent />
                                </v-tab-item>
                                <v-tab-item>
                                    <TermsAndConditionComponent />
                                </v-tab-item>

                                <v-tab-item>
                                    <PrivacyPolicyComponent />
                                </v-tab-item>
                                <v-tab-item>
                                <pet-insurance-component/>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-app>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import FaqComponent from "./components/faq.component.vue";
import TermsAndConditionComponent from "./components/termsAndCondition.component.vue";
import PrivacyPolicyComponent from "./components/privacyPolicy.component.vue";
import AboutComponent from "./components/about.component.vue";
import GeneralSettingComponent from "./components/generalSetting.component.vue";
import PetInsuranceComponent from './components/petInsurance.component.vue';
export default {
    name: "settings",
    components: {
        FaqComponent,
        TermsAndConditionComponent,
        PrivacyPolicyComponent,
        AboutComponent,
        GeneralSettingComponent,
        PetInsuranceComponent,
    },
    data() {
        return {
            tab: 5,
            items: [
                "General Settings",
                "FAQ",
                "About",
                "Terms & Conditions",
                "Privacy Policy",
                "Pet Insurance",
            ],
        };
    },
};
</script>
