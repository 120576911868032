<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-nutrition-create">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" to="/nutrition">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">Add a new item</h3>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <form @submit.prevent="saveNutrition">
              <v-app>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="vaccine">Item-EN</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter item in EN"
                        v-model="form.item_en"
                        @click="errors.item_en = null"
                      />
                      <small class="text-danger" v-if="errors.item_en"
                        >{{ errors.item_en[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="vaccine">Item-ZH</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.item_zh"
                        placeholder="Enter item in ZH"
                        @click="errors.item_zh = null"
                      />
                      <small class="text-danger" v-if="errors.item_zh"
                        >{{ errors.item_zh[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Category</label>
                      <v-select
                        v-model="form.category"
                        :items="categoryOptions"
                        item-text="name"
                        item-value="id"
                        outlined
                        placeholder="Select Category"
                        attach
                        @click="errors.category = null"
                      >
                        <template v-slot:append-item>
                          <v-list-item class="add-category-text">
                            <v-list-item-content>
                              <span
                                class="btn-text"
                                @click="AddNewCategory"
                                style="cursor: pointer; display: block"
                              >
                                Add new
                              </span>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                      <small class="text-danger" v-if="errors.category"
                        >{{ errors.category[0] }}
                      </small>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Pet type</label>
                      <v-select
                        v-model="form.pet_type"
                        :items="petTypeOption"
                        item-text="text"
                        item-value="val"
                        outlined
                        attach
                        @click="errors.pet_type = null"
                      >
                      </v-select>
                      <small class="text-danger" v-if="errors.pet_type"
                        >{{ errors.pet_type[0] }}
                      </small>
                    </div>
                  </div> -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="clinic">Edible</label>
                      <v-select
                        v-model="form.edible"
                        :items="edibleOption"
                        outlined
                        attach
                        @click="errors.edible = null"
                      >
                      </v-select>
                      <small class="text-danger" v-if="errors.edible"
                        >{{ errors.edible[0] }}
                      </small>
                    </div>
                  </div>
                  <div class="tab-header">
                    <div class="tab-wrap">
                      <template>
                        <v-tabs v-model="tab">
                          <v-tabs-slider color="#e77386"></v-tabs-slider>
                          <v-tab
                            v-for="(item, index) in items"
                            :class="{ active: currentTab === index }"
                            @click="currentTab = index"
                            :key="item"
                          >
                            {{ item }}
                          </v-tab>
                        </v-tabs>
                      </template>
                    </div>
                  </div>
                  <div class="tab-body">
                    <v-tabs-items v-model="tab">
                      <v-card flat>
                        <!-- Dog Tab -->
                        <div v-show="currentTab === 0">
                          <v-card flat>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Processed:</label>
                                    <v-radio-group
                                      v-model="form.dog_processed"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="dog_is_processed[1]"
                                        @click="errors.dog_processed = null"
                                      ></v-radio>

                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="dog_is_processed[0]"
                                        @click="errors.dog_processed = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.dog_processed"
                                      >{{ errors.dog_processed[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Dcm:</label>
                                    <v-radio-group
                                      v-model="form.dog_dcm"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="dog_is_dcm[1]"
                                        @click="errors.dog_dcm = null"
                                      ></v-radio>

                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="dog_is_dcm[0]"
                                        @click="errors.dog_dcm = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.dog_dcm"
                                      >{{ errors.dog_dcm[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Highfat:</label>
                                    <v-radio-group
                                      v-model="form.dog_highfat"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="dog_is_highfat[1]"
                                        @click="errors.dog_highfat = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="dog_is_highfat[0]"
                                        @click="errors.dog_highfat = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.dog_highfat"
                                      >{{ errors.dog_highfat[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Carb:</label>
                                    <v-radio-group
                                      v-model="form.dog_carb"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="dog_is_carb[1]"
                                        @click="errors.dog_carb = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="dog_is_carb[0]"
                                        @click="errors.dog_carb = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.dog_carb"
                                      >{{ errors.dog_carb[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Canned:</label>
                                    <v-radio-group
                                      v-model="form.dog_canned"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="dog_is_canned[1]"
                                        @click="errors.dog_canned = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="dog_is_canned[0]"
                                        @click="errors.dog_canned = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.dog_canned"
                                      >{{ errors.dog_canned[0] }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="clinic">Content-En</label>
                                  <v-textarea
                                    name=""
                                    value=""
                                    @keyup="lengthPad('form.dog_content_en')"
                                    :maxlength="max"
                                    placeholder="Enter content-EN"
                                    v-model="form.dog_content_en"
                                    @click="errors.dog_content_en = null"
                                  ></v-textarea>
                                  <small class="char-limit">
                                    {{ noteLength_dog_en }}/{{ max }}
                                  </small>
                                  <small
                                    class="text-danger"
                                    v-if="errors.dog_content_en"
                                    >{{ errors.dog_content_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="clinic">Content-Zh</label>
                                  <v-textarea
                                    name=""
                                    value=""
                                    @keyup="lengthPad('form.dog_content_zh')"
                                    :maxlength="max"
                                    placeholder="Enter content-ZH "
                                    v-model="form.dog_content_zh"
                                    @click="errors.dog_content_zh = null"
                                  ></v-textarea>
                                  <small class="char-limit">
                                    {{ noteLength_dog_zh }}/{{ max }}
                                  </small>
                                  <small
                                    class="text-danger"
                                    v-if="errors.dog_content_zh"
                                    >{{ errors.dog_content_zh[0] }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                        <!-- Cat Tab -->
                        <div v-show="currentTab === 1">
                          <v-card flat>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Processed:</label>
                                    <v-radio-group
                                      v-model="form.cat_processed"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="cat_is_processed[1]"
                                        @click="errors.cat_processed = null"
                                      ></v-radio>

                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="cat_is_processed[0]"
                                        @click="errors.cat_processed = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.cat_processed"
                                      >{{ errors.cat_processed[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Highfat:</label>
                                    <v-radio-group
                                      v-model="form.cat_highfat"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="cat_is_highfat[1]"
                                        @click="errors.cat_highfat = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="cat_is_highfat[0]"
                                        @click="errors.cat_highfat = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.cat_highfat"
                                      >{{ errors.cat_highfat[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Carb:</label>
                                    <v-radio-group
                                      v-model="form.cat_carb"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="cat_is_carb[1]"
                                        @click="errors.cat_carb = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="cat_is_carb[0]"
                                        @click="errors.cat_carb = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.cat_carb"
                                      >{{ errors.cat_carb[0] }}
                                    </small>
                                  </div>
                                  <div class="radio-group-wrap">
                                    <label for="Processed">Canned:</label>
                                    <v-radio-group
                                      v-model="form.cat_canned"
                                      column
                                    >
                                      <v-radio
                                        label="Yes"
                                        color="#E77386"
                                        :value="cat_is_canned[1]"
                                        @click="errors.cat_canned = null"
                                      ></v-radio>
                                      <v-radio
                                        label="No"
                                        color="#E77386"
                                        :value="cat_is_canned[0]"
                                        @click="errors.cat_canned = null"
                                      ></v-radio>
                                    </v-radio-group>
                                    <small
                                      class="text-danger"
                                      v-if="errors.cat_canned"
                                      >{{ errors.cat_canned[0] }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="clinic">Content-En</label>
                                  <v-textarea
                                    name=""
                                    value=""
                                    @keyup="lengthPad('form.cat_content_en')"
                                    :maxlength="max"
                                    placeholder="Enter content-EN"
                                    v-model="form.cat_content_en"
                                    @click="errors.cat_content_en = null"
                                  ></v-textarea>
                                  <small class="char-limit">
                                    {{ noteLength_cat_en }}/{{ max }}
                                  </small>
                                  <small
                                    class="text-danger"
                                    v-if="errors.cat_content_en"
                                    >{{ errors.cat_content_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="clinic">Content-Zh</label>
                                  <v-textarea
                                    name=""
                                    value=""
                                    @keyup="lengthPad('form.cat_content_zh')"
                                    :maxlength="max"
                                    placeholder="Enter content-ZH "
                                    v-model="form.cat_content_zh"
                                    @click="errors.cat_content_zh = null"
                                  ></v-textarea>
                                  <small class="char-limit">
                                    {{ noteLength_cat_zh }}/{{ max }}
                                  </small>
                                  <small
                                    class="text-danger"
                                    v-if="errors.cat_content_zh"
                                    >{{ errors.cat_content_zh[0] }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-card>
                    </v-tabs-items>
                  </div>
                </div>
                <v-dialog v-model="addCategory" max-width="600px">
                  <v-card class="add-category">
                    <v-card-title>
                      <span class="modal-title">New category</span>
                    </v-card-title>
                    <form @submit.prevent="addNutritionCategory">
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="vaccine">Category name(En)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="categoryForm.name_en"
                                @click="categoryErrors.name_en = null"
                              />
                              <small
                                class="text-danger"
                                v-if="categoryErrors.name_en"
                              >
                                {{ categoryErrors.name_en[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="vaccine">Category name(Zh)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="categoryForm.name_zh"
                                @click="categoryErrors.name_zh = null"
                              />
                              <small
                                class="text-danger"
                                v-if="categoryErrors.name_zh"
                              >
                                {{ categoryErrors.name_zh[0] }}
                              </small>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="vaccine">Image</label>
                              <ImageDragDrap
                                removeIcon="false"
                                max="10"
                                @imageDetails="checkboxValue"
                              ></ImageDragDrap>
                              <small
                                class="text-danger"
                                v-if="categoryErrors.image"
                              >
                                {{ categoryErrors.image[0] }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <div class="actions">
                          <button
                            type="submit"
                            class="btn btn-primary btn-save"
                            :disabled="loadingCategory"
                          >
                            Save
                            <span class="loading-dots" v-if="loadingCategory">
                            </span>
                          </button>
                          <button
                            type="reset"
                            class="btn btn-secondary btn-cancel"
                            @click="addCategory = false"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </v-card>
                </v-dialog>
              </v-app>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                Save <span class="loading-dots" v-if="loading"></span>
              </button>
            </form>
          </div>
          <div class="panel-footer">
            <div class="actions"></div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>



<script>
import BackArrow from "../icons/BackArrow.vue";
import ImageDragDrap from "../subComponents/imageDragDrop.vue";
export default {
  name: "NutritionCreate",
  components: {
    BackArrow,
    ImageDragDrap,
  },
  data() {
    return {
      currentTab: 0,
      tab: null,
      items: ["Dog", "Cat"],
      loading: false,
      loadingCategory: false,
      categoryOptions: [],
      // petTypeOption: [
      //   { text: "Dog", val: 0 },
      //   { text: "Cat", val: 1 },
      // ],
      edibleOption: ["Toxic", "Okay", "Good", "Great"],
      addCategory: false,
      dog_is_processed: [0, 1],
      dog_is_highfat: [0, 1],
      dog_is_dcm: [0, 1],
      dog_is_carb: [0, 1],
      dog_is_canned: [0, 1],
      cat_is_processed: [0, 1],
      cat_is_highfat: [0, 1],
      cat_is_carb: [0, 1],
      cat_is_canned: [0, 1],
      form: {
        item_en: "",
        item_zh: "",
        category: "",
        edible: "Toxic",
        dog_processed: 1,
        dog_dcm: 1,
        dog_carb: 1,
        dog_highfat: 1,
        dog_canned: 1,
        dog_content_en: "",
        dog_content_zh: "",
        cat_processed: 1,
        cat_carb: 1,
        cat_highfat: 1,
        cat_canned: 1,
        cat_content_en: "",
        cat_content_zh: "",
      },
      categoryForm: {
        name_en: "",
        name_zh: "",
        image: null,
      },
      errors: {},
      categoryErrors: {},
      max: "1500",
      noteLength_dog_en: "0",
      noteLength_dog_zh: "0",
      noteLength_cat_en: "0",
      noteLength_cat_zh: "0",
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getNutritionCategories();
  },
  methods: {
    AddNewCategory() {
      this.addCategory = true;
    },
    getNutritionCategories() {
      axios
        .get(`/admin/nutrition/category`)
        .then(({ data }) => {
          this.categoryOptions = data;
          // console.log(this.categoryOptions);
        })
        .catch((error) => console.log(error));
    },
    addNutritionCategory() {
      this.loadingCategory = true;
      axios
        .post(`/admin/nutrition/category/create`, this.categoryForm)
        .then(({ data }) => {
          this.addCategory = false;
          this.loadingCategory = false;
          Toast.success();
          this.getNutritionCategories();
          this.categoryForm.name_en = null;
          this.categoryForm.name_zh = null;
          this.categoryForm.image = null;
          this.form.category = this.categoryOptions.length + 1;
        })
        .catch((error) => {
          this.categoryErrors = error.response.data.errors;
          // console.log(this.categoryErrors);
          this.loadingCategory = false;
        });
    },
    saveNutrition() {
      this.loading = true;
      axios
        .post(`/admin/nutrition/create`, this.form)
        .then(({ data }) => {
          this.loading = false;
          Toast.success();
          this.$router.push({ name: "Nutrition" });
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors;
          // console.log(this.errors);
        });
    },
    checkboxValue(data) {
      // console.log('hello'+data);
      this.categoryErrors.image = null;
      this.categoryForm.image = data;
    },
    lengthPad(num) {
      if (num == "form.dog_content_en") {
        let lengthDog = this.form.dog_content_en.length;
        this.noteLength_dog_en = ("" + lengthDog).slice(-3);
      } else if (num == "form.dog_content_zh") {
        let lengthDog = this.form.dog_content_zh.length;
        this.noteLength_dog_zh = ("" + lengthDog).slice(-3);
      } else if (num == "form.cat_content_en") {
        let lengthCat = this.form.cat_content_en.length;
        this.noteLength_cat_en = ("" + lengthCat).slice(-3);
      } else if (num == "form.cat_content_zh") {
        let lengthCat = this.form.cat_content_zh.length;
        this.noteLength_cat_zh = ("" + lengthCat).slice(-3);
      }
    },
  },
};
</script>

<style>
</style>
