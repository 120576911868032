<template>
    <section class="section mt-5">
        <div class="panel">
            <div class="panel-header">
                <div class="title-wrapper">
                    <h2 class="panel-title">General Settings</h2>
                </div>
            </div>
            <div class="panel-body">
                <form @submit.prevent="submit">
                    <v-app>
                        <v-row>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="label"
                                        >Limitation for Pets</label
                                    >
                                    <input
                                        type="number"
                                        class="form-control"
                                        min="1"
                                        placeholder=""
                                        v-model="form.limits"
                                        @click="errors.limits = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.limits"
                                        >{{ errors.limits[0] }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="label"
                                        >Facebook</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="form.facebook"
                                        @click="errors.facebook = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.facebook"
                                        >{{ errors.facebook[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="label"
                                        >Instagram</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="form.instagram"
                                        @click="errors.instagram = null"
                                    />
                                    <small
                                        class="text-danger"
                                        v-if="errors.instagram"
                                        >{{ errors.instagram[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="col col-12">
                                <button
                                    class="btn btn-primary btn-save"
                                    :disabled="loading"
                                >
                                    Save
                                    <span class="loading-dots" v-if="loading">
                                    </span>
                                </button>
                            </div>
                        </v-row>
                    </v-app>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "GeneralSettingComponent",
    data() {
        return {
            loading: false,
            form: {
                limits: "",
                facebook: "",
                instagram: "",
            },
            errors: {},
        };
    },
    created() {
        const token = localStorage.getItem("access_token");
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true; 
            axios
                .get(`/admin/settings/general_setting`)
                .then(({ data }) => {
                    this.form.limits = data.data.limit_of_pets;
                    this.form.facebook = data.data.facebook;
                    this.form.instagram = data.data.instagram;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            axios
                .post(`/admin/settings/general_setting/store`, this.form)
                .then(({ data }) => {
                    this.loading = false;
                    Toast.success();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.panel-body {
    max-width: 1060px;
}
.form-group {
    margin-bottom: 20px;
    .label {
        margin-bottom: 8px;
    }
}
.btn-save {
    &:disabled {
        background-color: var(--primary-color);
    }
    .loading-dots {
        position: relative;
        top: 1px;
        left: unset;
    }
}
</style>
